* {
  font-family: 'Montserrat', sans-serif;

}

.onPage {
  color: rgb(255, 255, 255);
  text-decoration: underline
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;0,800;0,900;1,500;1,600;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Exo:wght@900&display=swap');

.page-loader {
  width: 100%;
  height: 100vh;
  background: #242424;
  z-index: 5000000000000000;
  position: fixed;
}

.loading-area {
  display: grid;
  place-items: center;
  height: 100vh;
}

.containerLoad {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.h1Load {
  font-size: 1.5rem;
  color: #00ff2a;
}

.ring {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.ring::before {
  content: '';
  width: 200px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: ring 2s linear infinite;
}

@keyframes ring {
  0% {
    transform: rotate(0deg);
    box-shadow: 0px 5px 5px #008afa;
  }

  25% {
    transform: rotate(90deg);
    box-shadow: 0px 5px 5px #02b7ff;
  }

  50% {
    transform: rotate(180deg);
    box-shadow: 0px 5px 5px #0151ff;
  }

  75% {
    transform: rotate(270deg);
    box-shadow: 0px 5px 5px #367de7;
  }

  100% {
    transform: rotate(360deg);
    box-shadow: 0px 5px 5px #008afa;
  }
}


.title:hover {
  cursor: pointer;
}


::-webkit-scrollbar {
  width: 13px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fb2ef9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b600b3;
}

@keyframes fade-out {
  0% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

@keyframes reveal {
  0% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

.reveal {
  animation: reveal 2s forwards;
}

.spinner {
  position: relative;
  top: 35%;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}




.loadLogo img {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  animation: zoom 5s infinite;
}

.loadingText2:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

.freemintLoading {
  font-size: 25px;
  margin-top: 3%;
  background: -webkit-linear-gradient(#1df739, #b3fe00, #b3fe00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: italic;
  font-weight: bold;
}


.fadeOut {
  animation: fade 10s ease-in 0s forwards;
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

.anim-fade-out {
  animation: fade-out 1s forwards;
}

.fire {
  width: 70px;
  height: 70px;
  margin-right: 10px;
}

.fireGlow {
  animation: glow 2s ease-in-out infinite alternate;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin-right: 10px;
}

.firefly {
  position: sticky;
  position: -webkit-sticky;
  left: 50%;
  top: 50%;
  width: 0.4vw;
  height: 0.4vw;
  margin: -0.2vw 0 0 9.8vw;
  animation: ease 200s alternate infinite;
  pointer-events: none;
}

.firefly::before,
.firefly::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform-origin: -10vw;
}

.firefly::before {
  background: black;
  opacity: 0.4;
  animation: drift ease alternate infinite;
}

.firefly::after {
  background: white;
  opacity: 0;
  box-shadow: 0 0 0vw 0vw #31ff40;
  animation: drift ease alternate infinite, flash ease infinite;
}

.firefly:nth-child(1) {
  animation-name: move1;
}

.firefly:nth-child(1)::before {
  animation-duration: 10s;
}

.firefly:nth-child(1)::after {
  animation-duration: 10s, 5869ms;
  animation-delay: 0ms, 5103ms;
}

@keyframes move1 {
  0% {
    transform: translateX(-41vw) translateY(23vh) scale(0.42);
  }

  5% {
    transform: translateX(9vw) translateY(-45vh) scale(0.34);
  }

  10% {
    transform: translateX(-17vw) translateY(-42vh) scale(1);
  }

  15% {
    transform: translateX(8vw) translateY(-23vh) scale(0.51);
  }

  20% {
    transform: translateX(-29vw) translateY(-20vh) scale(0.56);
  }

  25% {
    transform: translateX(8vw) translateY(16vh) scale(0.79);
  }

  30% {
    transform: translateX(11vw) translateY(46vh) scale(0.54);
  }

  35% {
    transform: translateX(9vw) translateY(-45vh) scale(0.58);
  }

  40% {
    transform: translateX(10vw) translateY(-37vh) scale(0.69);
  }

  45% {
    transform: translateX(5vw) translateY(26vh) scale(0.52);
  }

  50% {
    transform: translateX(-36vw) translateY(-39vh) scale(0.43);
  }

  55% {
    transform: translateX(-45vw) translateY(35vh) scale(0.96);
  }

  60% {
    transform: translateX(17vw) translateY(38vh) scale(0.66);
  }

  65% {
    transform: translateX(-39vw) translateY(-34vh) scale(0.75);
  }

  70% {
    transform: translateX(-45vw) translateY(18vh) scale(0.72);
  }

  75% {
    transform: translateX(48vw) translateY(21vh) scale(0.42);
  }

  80% {
    transform: translateX(28vw) translateY(-2vh) scale(0.82);
  }

  85% {
    transform: translateX(-29vw) translateY(30vh) scale(0.48);
  }

  90% {
    transform: translateX(12vw) translateY(16vh) scale(0.42);
  }

  95% {
    transform: translateX(-8vw) translateY(-24vh) scale(0.52);
  }

  100% {
    transform: translateX(-49vw) translateY(6vh) scale(0.34);
  }
}

.firefly:nth-child(2) {
  animation-name: move2;
}

.firefly:nth-child(2)::before {
  animation-duration: 13s;
}

.firefly:nth-child(2)::after {
  animation-duration: 13s, 8622ms;
  animation-delay: 0ms, 6050ms;
}

@keyframes move2 {
  0% {
    transform: translateX(-13vw) translateY(17vh) scale(0.94);
  }

  4.347826087% {
    transform: translateX(12vw) translateY(24vh) scale(0.48);
  }

  8.6956521739% {
    transform: translateX(16vw) translateY(-40vh) scale(0.39);
  }

  13.0434782609% {
    transform: translateX(22vw) translateY(22vh) scale(0.68);
  }

  17.3913043478% {
    transform: translateX(19vw) translateY(-14vh) scale(0.42);
  }

  21.7391304348% {
    transform: translateX(2vw) translateY(-18vh) scale(0.3);
  }

  26.0869565217% {
    transform: translateX(2vw) translateY(38vh) scale(0.29);
  }

  30.4347826087% {
    transform: translateX(28vw) translateY(6vh) scale(0.87);
  }

  34.7826086957% {
    transform: translateX(-48vw) translateY(21vh) scale(0.88);
  }

  39.1304347826% {
    transform: translateX(32vw) translateY(15vh) scale(0.76);
  }

  43.4782608696% {
    transform: translateX(32vw) translateY(1vh) scale(0.31);
  }

  47.8260869565% {
    transform: translateX(35vw) translateY(0vh) scale(0.29);
  }

  52.1739130435% {
    transform: translateX(9vw) translateY(14vh) scale(0.27);
  }

  56.5217391304% {
    transform: translateX(12vw) translateY(-20vh) scale(0.64);
  }

  60.8695652174% {
    transform: translateX(-21vw) translateY(27vh) scale(0.72);
  }

  65.2173913043% {
    transform: translateX(-47vw) translateY(-33vh) scale(0.31);
  }

  69.5652173913% {
    transform: translateX(-25vw) translateY(7vh) scale(0.85);
  }

  73.9130434783% {
    transform: translateX(42vw) translateY(38vh) scale(0.88);
  }

  78.2608695652% {
    transform: translateX(11vw) translateY(10vh) scale(0.91);
  }

  82.6086956522% {
    transform: translateX(2vw) translateY(6vh) scale(0.59);
  }

  86.9565217391% {
    transform: translateX(37vw) translateY(4vh) scale(0.59);
  }

  91.3043478261% {
    transform: translateX(-34vw) translateY(-32vh) scale(0.95);
  }

  95.652173913% {
    transform: translateX(-47vw) translateY(32vh) scale(0.81);
  }

  100% {
    transform: translateX(32vw) translateY(22vh) scale(0.53);
  }
}

.firefly:nth-child(3) {
  animation-name: move3;
}

.firefly:nth-child(3)::before {
  animation-duration: 14s;
}

.firefly:nth-child(3)::after {
  animation-duration: 14s, 7972ms;
  animation-delay: 0ms, 2219ms;
}

@keyframes move3 {
  0% {
    transform: translateX(-9vw) translateY(-12vh) scale(0.35);
  }

  4% {
    transform: translateX(9vw) translateY(45vh) scale(0.75);
  }

  8% {
    transform: translateX(37vw) translateY(-35vh) scale(0.42);
  }

  12% {
    transform: translateX(-49vw) translateY(5vh) scale(0.46);
  }

  16% {
    transform: translateX(42vw) translateY(26vh) scale(0.34);
  }

  20% {
    transform: translateX(-12vw) translateY(19vh) scale(0.84);
  }

  24% {
    transform: translateX(23vw) translateY(6vh) scale(0.45);
  }

  28% {
    transform: translateX(18vw) translateY(-16vh) scale(0.47);
  }

  32% {
    transform: translateX(22vw) translateY(-40vh) scale(0.45);
  }

  36% {
    transform: translateX(-37vw) translateY(33vh) scale(0.75);
  }

  40% {
    transform: translateX(44vw) translateY(33vh) scale(0.61);
  }

  44% {
    transform: translateX(-44vw) translateY(-18vh) scale(0.57);
  }

  48% {
    transform: translateX(-9vw) translateY(39vh) scale(0.45);
  }

  52% {
    transform: translateX(10vw) translateY(41vh) scale(0.51);
  }

  56% {
    transform: translateX(23vw) translateY(-41vh) scale(0.31);
  }

  60% {
    transform: translateX(48vw) translateY(-5vh) scale(0.33);
  }

  64% {
    transform: translateX(-49vw) translateY(-43vh) scale(0.3);
  }

  68% {
    transform: translateX(-39vw) translateY(5vh) scale(0.44);
  }

  72% {
    transform: translateX(-18vw) translateY(-28vh) scale(0.94);
  }

  76% {
    transform: translateX(22vw) translateY(-18vh) scale(0.67);
  }

  80% {
    transform: translateX(10vw) translateY(-32vh) scale(0.56);
  }

  84% {
    transform: translateX(-7vw) translateY(-48vh) scale(0.47);
  }

  88% {
    transform: translateX(30vw) translateY(37vh) scale(0.64);
  }

  92% {
    transform: translateX(-46vw) translateY(-37vh) scale(0.53);
  }

  96% {
    transform: translateX(9vw) translateY(40vh) scale(0.87);
  }

  100% {
    transform: translateX(-48vw) translateY(-17vh) scale(0.46);
  }
}

.firefly:nth-child(4) {
  animation-name: move4;
}

.firefly:nth-child(4)::before {
  animation-duration: 10s;
}

.firefly:nth-child(4)::after {
  animation-duration: 10s, 8866ms;
  animation-delay: 0ms, 4122ms;
}

@keyframes move4 {
  0% {
    transform: translateX(-1vw) translateY(17vh) scale(0.76);
  }

  5.5555555556% {
    transform: translateX(-20vw) translateY(-6vh) scale(0.96);
  }

  11.1111111111% {
    transform: translateX(7vw) translateY(27vh) scale(0.44);
  }

  16.6666666667% {
    transform: translateX(1vw) translateY(32vh) scale(0.75);
  }

  22.2222222222% {
    transform: translateX(48vw) translateY(-14vh) scale(0.83);
  }

  27.7777777778% {
    transform: translateX(-34vw) translateY(-30vh) scale(0.6);
  }

  33.3333333333% {
    transform: translateX(40vw) translateY(-27vh) scale(1);
  }

  38.8888888889% {
    transform: translateX(-14vw) translateY(-28vh) scale(0.29);
  }

  44.4444444444% {
    transform: translateX(37vw) translateY(-14vh) scale(0.56);
  }

  50% {
    transform: translateX(29vw) translateY(8vh) scale(0.37);
  }

  55.5555555556% {
    transform: translateX(-14vw) translateY(21vh) scale(0.39);
  }

  61.1111111111% {
    transform: translateX(-1vw) translateY(38vh) scale(0.64);
  }

  66.6666666667% {
    transform: translateX(40vw) translateY(-17vh) scale(0.78);
  }

  72.2222222222% {
    transform: translateX(-6vw) translateY(-45vh) scale(0.54);
  }

  77.7777777778% {
    transform: translateX(-47vw) translateY(-46vh) scale(0.38);
  }

  83.3333333333% {
    transform: translateX(38vw) translateY(-13vh) scale(0.86);
  }

  88.8888888889% {
    transform: translateX(-8vw) translateY(35vh) scale(0.39);
  }

  94.4444444444% {
    transform: translateX(-25vw) translateY(-1vh) scale(0.44);
  }

  100% {
    transform: translateX(0vw) translateY(-34vh) scale(0.57);
  }
}

.firefly:nth-child(5) {
  animation-name: move5;
}

.firefly:nth-child(5)::before {
  animation-duration: 18s;
}

.firefly:nth-child(5)::after {
  animation-duration: 18s, 6640ms;
  animation-delay: 0ms, 1937ms;
}

@keyframes move5 {
  0% {
    transform: translateX(33vw) translateY(-14vh) scale(0.83);
  }

  4.5454545455% {
    transform: translateX(-14vw) translateY(6vh) scale(0.26);
  }

  9.0909090909% {
    transform: translateX(-15vw) translateY(-12vh) scale(0.56);
  }

  13.6363636364% {
    transform: translateX(39vw) translateY(20vh) scale(0.69);
  }

  18.1818181818% {
    transform: translateX(-21vw) translateY(-1vh) scale(0.27);
  }

  22.7272727273% {
    transform: translateX(-38vw) translateY(47vh) scale(0.43);
  }

  27.2727272727% {
    transform: translateX(10vw) translateY(-4vh) scale(0.53);
  }

  31.8181818182% {
    transform: translateX(-13vw) translateY(21vh) scale(0.88);
  }

  36.3636363636% {
    transform: translateX(-3vw) translateY(46vh) scale(0.76);
  }

  40.9090909091% {
    transform: translateX(-2vw) translateY(-11vh) scale(0.32);
  }

  45.4545454545% {
    transform: translateX(14vw) translateY(4vh) scale(0.32);
  }

  50% {
    transform: translateX(8vw) translateY(8vh) scale(0.76);
  }

  54.5454545455% {
    transform: translateX(18vw) translateY(-35vh) scale(0.6);
  }

  59.0909090909% {
    transform: translateX(15vw) translateY(-43vh) scale(0.28);
  }

  63.6363636364% {
    transform: translateX(-12vw) translateY(12vh) scale(0.68);
  }

  68.1818181818% {
    transform: translateX(5vw) translateY(25vh) scale(0.96);
  }

  72.7272727273% {
    transform: translateX(-21vw) translateY(-35vh) scale(0.4);
  }

  77.2727272727% {
    transform: translateX(-44vw) translateY(7vh) scale(0.98);
  }

  81.8181818182% {
    transform: translateX(40vw) translateY(-29vh) scale(0.91);
  }

  86.3636363636% {
    transform: translateX(30vw) translateY(-36vh) scale(0.49);
  }

  90.9090909091% {
    transform: translateX(48vw) translateY(22vh) scale(0.67);
  }

  95.4545454545% {
    transform: translateX(18vw) translateY(-10vh) scale(0.77);
  }

  100% {
    transform: translateX(-40vw) translateY(-6vh) scale(0.91);
  }
}

.firefly:nth-child(6) {
  animation-name: move6;
}

.firefly:nth-child(6)::before {
  animation-duration: 15s;
}

.firefly:nth-child(6)::after {
  animation-duration: 15s, 8079ms;
  animation-delay: 0ms, 6703ms;
}

@keyframes move6 {
  0% {
    transform: translateX(7vw) translateY(-40vh) scale(0.74);
  }

  4.7619047619% {
    transform: translateX(-23vw) translateY(32vh) scale(0.7);
  }

  9.5238095238% {
    transform: translateX(-9vw) translateY(0vh) scale(0.34);
  }

  14.2857142857% {
    transform: translateX(-36vw) translateY(18vh) scale(0.65);
  }

  19.0476190476% {
    transform: translateX(37vw) translateY(-8vh) scale(0.84);
  }

  23.8095238095% {
    transform: translateX(7vw) translateY(27vh) scale(0.44);
  }

  28.5714285714% {
    transform: translateX(-3vw) translateY(8vh) scale(0.93);
  }

  33.3333333333% {
    transform: translateX(-18vw) translateY(5vh) scale(0.87);
  }

  38.0952380952% {
    transform: translateX(25vw) translateY(19vh) scale(0.45);
  }

  42.8571428571% {
    transform: translateX(46vw) translateY(14vh) scale(0.95);
  }

  47.619047619% {
    transform: translateX(13vw) translateY(-10vh) scale(0.5);
  }

  52.380952381% {
    transform: translateX(15vw) translateY(11vh) scale(0.4);
  }

  57.1428571429% {
    transform: translateX(15vw) translateY(-35vh) scale(0.96);
  }

  61.9047619048% {
    transform: translateX(-13vw) translateY(41vh) scale(0.35);
  }

  66.6666666667% {
    transform: translateX(-23vw) translateY(26vh) scale(0.9);
  }

  71.4285714286% {
    transform: translateX(-7vw) translateY(4vh) scale(0.81);
  }

  76.1904761905% {
    transform: translateX(-49vw) translateY(-18vh) scale(0.84);
  }

  80.9523809524% {
    transform: translateX(26vw) translateY(33vh) scale(0.43);
  }

  85.7142857143% {
    transform: translateX(-6vw) translateY(23vh) scale(0.48);
  }

  90.4761904762% {
    transform: translateX(46vw) translateY(-5vh) scale(0.8);
  }

  95.2380952381% {
    transform: translateX(42vw) translateY(33vh) scale(0.6);
  }

  100% {
    transform: translateX(-28vw) translateY(26vh) scale(0.64);
  }
}

.firefly:nth-child(7) {
  animation-name: move7;
}

.firefly:nth-child(7)::before {
  animation-duration: 17s;
}

.firefly:nth-child(7)::after {
  animation-duration: 17s, 9827ms;
  animation-delay: 0ms, 3278ms;
}

@keyframes move7 {
  0% {
    transform: translateX(-1vw) translateY(41vh) scale(0.51);
  }

  5.5555555556% {
    transform: translateX(38vw) translateY(32vh) scale(0.61);
  }

  11.1111111111% {
    transform: translateX(36vw) translateY(44vh) scale(0.89);
  }

  16.6666666667% {
    transform: translateX(-22vw) translateY(-36vh) scale(0.68);
  }

  22.2222222222% {
    transform: translateX(4vw) translateY(-2vh) scale(0.87);
  }

  27.7777777778% {
    transform: translateX(11vw) translateY(-5vh) scale(0.48);
  }

  33.3333333333% {
    transform: translateX(-30vw) translateY(28vh) scale(0.43);
  }

  38.8888888889% {
    transform: translateX(-38vw) translateY(-4vh) scale(0.84);
  }

  44.4444444444% {
    transform: translateX(-29vw) translateY(33vh) scale(0.43);
  }

  50% {
    transform: translateX(1vw) translateY(-4vh) scale(0.96);
  }

  55.5555555556% {
    transform: translateX(38vw) translateY(-23vh) scale(0.5);
  }

  61.1111111111% {
    transform: translateX(-47vw) translateY(-1vh) scale(0.91);
  }

  66.6666666667% {
    transform: translateX(40vw) translateY(-8vh) scale(0.52);
  }

  72.2222222222% {
    transform: translateX(26vw) translateY(14vh) scale(0.83);
  }

  77.7777777778% {
    transform: translateX(17vw) translateY(-48vh) scale(0.97);
  }

  83.3333333333% {
    transform: translateX(-22vw) translateY(45vh) scale(0.85);
  }

  88.8888888889% {
    transform: translateX(-21vw) translateY(-9vh) scale(0.45);
  }

  94.4444444444% {
    transform: translateX(39vw) translateY(38vh) scale(0.59);
  }

  100% {
    transform: translateX(-37vw) translateY(-2vh) scale(0.55);
  }
}

.firefly:nth-child(8) {
  animation-name: move8;
}

.firefly:nth-child(8)::before {
  animation-duration: 9s;
}

.firefly:nth-child(8)::after {
  animation-duration: 9s, 8380ms;
  animation-delay: 0ms, 4603ms;
}

@keyframes move8 {
  0% {
    transform: translateX(-17vw) translateY(30vh) scale(0.58);
  }

  5.8823529412% {
    transform: translateX(-13vw) translateY(-42vh) scale(0.82);
  }

  11.7647058824% {
    transform: translateX(-10vw) translateY(-38vh) scale(0.86);
  }

  17.6470588235% {
    transform: translateX(49vw) translateY(47vh) scale(0.97);
  }

  23.5294117647% {
    transform: translateX(-17vw) translateY(-44vh) scale(1);
  }

  29.4117647059% {
    transform: translateX(-6vw) translateY(-20vh) scale(0.5);
  }

  35.2941176471% {
    transform: translateX(-39vw) translateY(-34vh) scale(0.33);
  }

  41.1764705882% {
    transform: translateX(38vw) translateY(49vh) scale(0.45);
  }

  47.0588235294% {
    transform: translateX(43vw) translateY(39vh) scale(0.43);
  }

  52.9411764706% {
    transform: translateX(46vw) translateY(-4vh) scale(0.27);
  }

  58.8235294118% {
    transform: translateX(-16vw) translateY(-20vh) scale(0.71);
  }

  64.7058823529% {
    transform: translateX(-43vw) translateY(-40vh) scale(0.84);
  }

  70.5882352941% {
    transform: translateX(-10vw) translateY(13vh) scale(0.81);
  }

  76.4705882353% {
    transform: translateX(38vw) translateY(1vh) scale(0.36);
  }

  82.3529411765% {
    transform: translateX(32vw) translateY(-40vh) scale(0.71);
  }

  88.2352941176% {
    transform: translateX(0vw) translateY(-49vh) scale(0.32);
  }

  94.1176470588% {
    transform: translateX(12vw) translateY(-49vh) scale(0.29);
  }

  100% {
    transform: translateX(7vw) translateY(-3vh) scale(0.94);
  }
}

.firefly:nth-child(9) {
  animation-name: move9;
}

.firefly:nth-child(9)::before {
  animation-duration: 18s;
}

.firefly:nth-child(9)::after {
  animation-duration: 18s, 10823ms;
  animation-delay: 0ms, 705ms;
}

@keyframes move9 {
  0% {
    transform: translateX(3vw) translateY(4vh) scale(0.41);
  }

  5.2631578947% {
    transform: translateX(-43vw) translateY(40vh) scale(0.79);
  }

  10.5263157895% {
    transform: translateX(-39vw) translateY(22vh) scale(0.38);
  }

  15.7894736842% {
    transform: translateX(-32vw) translateY(1vh) scale(0.41);
  }

  21.0526315789% {
    transform: translateX(-33vw) translateY(31vh) scale(0.81);
  }

  26.3157894737% {
    transform: translateX(2vw) translateY(-33vh) scale(0.43);
  }

  31.5789473684% {
    transform: translateX(-2vw) translateY(41vh) scale(0.78);
  }

  36.8421052632% {
    transform: translateX(-1vw) translateY(25vh) scale(0.69);
  }

  42.1052631579% {
    transform: translateX(-39vw) translateY(-28vh) scale(0.94);
  }

  47.3684210526% {
    transform: translateX(29vw) translateY(13vh) scale(0.42);
  }

  52.6315789474% {
    transform: translateX(42vw) translateY(17vh) scale(0.47);
  }

  57.8947368421% {
    transform: translateX(14vw) translateY(-9vh) scale(0.59);
  }

  63.1578947368% {
    transform: translateX(12vw) translateY(-8vh) scale(0.29);
  }

  68.4210526316% {
    transform: translateX(-31vw) translateY(-25vh) scale(0.81);
  }

  73.6842105263% {
    transform: translateX(-45vw) translateY(13vh) scale(0.33);
  }

  78.9473684211% {
    transform: translateX(-6vw) translateY(-48vh) scale(0.86);
  }

  84.2105263158% {
    transform: translateX(-18vw) translateY(-12vh) scale(0.99);
  }

  89.4736842105% {
    transform: translateX(22vw) translateY(-39vh) scale(0.51);
  }

  94.7368421053% {
    transform: translateX(43vw) translateY(-15vh) scale(0.6);
  }

  100% {
    transform: translateX(41vw) translateY(-14vh) scale(0.56);
  }
}

.firefly:nth-child(10) {
  animation-name: move10;
}

.firefly:nth-child(10)::before {
  animation-duration: 15s;
}

.firefly:nth-child(10)::after {
  animation-duration: 15s, 9714ms;
  animation-delay: 0ms, 7928ms;
}

@keyframes move10 {
  0% {
    transform: translateX(-5vw) translateY(25vh) scale(0.3);
  }

  3.8461538462% {
    transform: translateX(11vw) translateY(42vh) scale(0.81);
  }

  7.6923076923% {
    transform: translateX(-45vw) translateY(25vh) scale(0.44);
  }

  11.5384615385% {
    transform: translateX(23vw) translateY(-4vh) scale(0.43);
  }

  15.3846153846% {
    transform: translateX(10vw) translateY(12vh) scale(0.84);
  }

  19.2307692308% {
    transform: translateX(-33vw) translateY(38vh) scale(0.95);
  }

  23.0769230769% {
    transform: translateX(15vw) translateY(23vh) scale(0.56);
  }

  26.9230769231% {
    transform: translateX(-12vw) translateY(-17vh) scale(0.42);
  }

  30.7692307692% {
    transform: translateX(1vw) translateY(-34vh) scale(0.61);
  }

  34.6153846154% {
    transform: translateX(22vw) translateY(-25vh) scale(0.32);
  }

  38.4615384615% {
    transform: translateX(-17vw) translateY(26vh) scale(0.65);
  }

  42.3076923077% {
    transform: translateX(3vw) translateY(29vh) scale(0.91);
  }

  46.1538461538% {
    transform: translateX(-12vw) translateY(-36vh) scale(0.64);
  }

  50% {
    transform: translateX(-44vw) translateY(41vh) scale(0.44);
  }

  53.8461538462% {
    transform: translateX(9vw) translateY(-29vh) scale(0.6);
  }

  57.6923076923% {
    transform: translateX(22vw) translateY(30vh) scale(0.98);
  }

  61.5384615385% {
    transform: translateX(-20vw) translateY(33vh) scale(0.7);
  }

  65.3846153846% {
    transform: translateX(40vw) translateY(24vh) scale(0.38);
  }

  69.2307692308% {
    transform: translateX(-19vw) translateY(4vh) scale(0.48);
  }

  73.0769230769% {
    transform: translateX(-18vw) translateY(-32vh) scale(1);
  }

  76.9230769231% {
    transform: translateX(17vw) translateY(1vh) scale(0.68);
  }

  80.7692307692% {
    transform: translateX(-40vw) translateY(-16vh) scale(0.92);
  }

  84.6153846154% {
    transform: translateX(17vw) translateY(32vh) scale(0.9);
  }

  88.4615384615% {
    transform: translateX(14vw) translateY(45vh) scale(0.8);
  }

  92.3076923077% {
    transform: translateX(14vw) translateY(50vh) scale(0.74);
  }

  96.1538461538% {
    transform: translateX(26vw) translateY(47vh) scale(0.83);
  }

  100% {
    transform: translateX(49vw) translateY(-40vh) scale(0.95);
  }
}

.firefly:nth-child(11) {
  animation-name: move11;
}

.firefly:nth-child(11)::before {
  animation-duration: 13s;
}

.firefly:nth-child(11)::after {
  animation-duration: 13s, 7476ms;
  animation-delay: 0ms, 5436ms;
}

@keyframes move11 {
  0% {
    transform: translateX(-7vw) translateY(16vh) scale(0.42);
  }

  4.1666666667% {
    transform: translateX(-44vw) translateY(14vh) scale(0.89);
  }

  8.3333333333% {
    transform: translateX(17vw) translateY(-25vh) scale(0.72);
  }

  12.5% {
    transform: translateX(-40vw) translateY(-22vh) scale(0.47);
  }

  16.6666666667% {
    transform: translateX(49vw) translateY(12vh) scale(0.93);
  }

  20.8333333333% {
    transform: translateX(-45vw) translateY(21vh) scale(0.6);
  }

  25% {
    transform: translateX(36vw) translateY(-41vh) scale(0.48);
  }

  29.1666666667% {
    transform: translateX(-9vw) translateY(0vh) scale(0.98);
  }

  33.3333333333% {
    transform: translateX(-32vw) translateY(37vh) scale(0.29);
  }

  37.5% {
    transform: translateX(31vw) translateY(-46vh) scale(0.34);
  }

  41.6666666667% {
    transform: translateX(21vw) translateY(-45vh) scale(0.51);
  }

  45.8333333333% {
    transform: translateX(-12vw) translateY(-46vh) scale(0.87);
  }

  50% {
    transform: translateX(25vw) translateY(28vh) scale(0.48);
  }

  54.1666666667% {
    transform: translateX(-16vw) translateY(-25vh) scale(0.76);
  }

  58.3333333333% {
    transform: translateX(-4vw) translateY(48vh) scale(0.63);
  }

  62.5% {
    transform: translateX(48vw) translateY(-32vh) scale(0.31);
  }

  66.6666666667% {
    transform: translateX(-39vw) translateY(-39vh) scale(0.56);
  }

  70.8333333333% {
    transform: translateX(-1vw) translateY(37vh) scale(0.5);
  }

  75% {
    transform: translateX(-9vw) translateY(-5vh) scale(0.56);
  }

  79.1666666667% {
    transform: translateX(-11vw) translateY(13vh) scale(0.96);
  }

  83.3333333333% {
    transform: translateX(-1vw) translateY(-41vh) scale(0.55);
  }

  87.5% {
    transform: translateX(17vw) translateY(18vh) scale(0.58);
  }

  91.6666666667% {
    transform: translateX(-12vw) translateY(10vh) scale(0.99);
  }

  95.8333333333% {
    transform: translateX(33vw) translateY(50vh) scale(0.85);
  }

  100% {
    transform: translateX(43vw) translateY(38vh) scale(0.52);
  }
}

.firefly:nth-child(12) {
  animation-name: move12;
}

.firefly:nth-child(12)::before {
  animation-duration: 13s;
}

.firefly:nth-child(12)::after {
  animation-duration: 13s, 5428ms;
  animation-delay: 0ms, 4447ms;
}

@keyframes move12 {
  0% {
    transform: translateX(19vw) translateY(-25vh) scale(0.71);
  }

  3.7037037037% {
    transform: translateX(5vw) translateY(-44vh) scale(0.68);
  }

  7.4074074074% {
    transform: translateX(-11vw) translateY(21vh) scale(0.96);
  }

  11.1111111111% {
    transform: translateX(-16vw) translateY(-35vh) scale(0.62);
  }

  14.8148148148% {
    transform: translateX(19vw) translateY(36vh) scale(0.51);
  }

  18.5185185185% {
    transform: translateX(-31vw) translateY(39vh) scale(0.91);
  }

  22.2222222222% {
    transform: translateX(-13vw) translateY(-33vh) scale(0.57);
  }

  25.9259259259% {
    transform: translateX(-11vw) translateY(-43vh) scale(0.91);
  }

  29.6296296296% {
    transform: translateX(6vw) translateY(-23vh) scale(0.35);
  }

  33.3333333333% {
    transform: translateX(50vw) translateY(-5vh) scale(1);
  }

  37.037037037% {
    transform: translateX(-42vw) translateY(0vh) scale(0.98);
  }

  40.7407407407% {
    transform: translateX(35vw) translateY(40vh) scale(0.97);
  }

  44.4444444444% {
    transform: translateX(-6vw) translateY(-30vh) scale(0.28);
  }

  48.1481481481% {
    transform: translateX(7vw) translateY(0vh) scale(0.85);
  }

  51.8518518519% {
    transform: translateX(-29vw) translateY(-42vh) scale(0.82);
  }

  55.5555555556% {
    transform: translateX(-13vw) translateY(-39vh) scale(0.93);
  }

  59.2592592593% {
    transform: translateX(-16vw) translateY(43vh) scale(0.99);
  }

  62.962962963% {
    transform: translateX(19vw) translateY(38vh) scale(0.61);
  }

  66.6666666667% {
    transform: translateX(13vw) translateY(26vh) scale(0.75);
  }

  70.3703703704% {
    transform: translateX(-3vw) translateY(-23vh) scale(0.7);
  }

  74.0740740741% {
    transform: translateX(-19vw) translateY(3vh) scale(0.93);
  }

  77.7777777778% {
    transform: translateX(8vw) translateY(-18vh) scale(0.77);
  }

  81.4814814815% {
    transform: translateX(-23vw) translateY(-12vh) scale(0.88);
  }

  85.1851851852% {
    transform: translateX(29vw) translateY(-27vh) scale(0.75);
  }

  88.8888888889% {
    transform: translateX(-28vw) translateY(-32vh) scale(0.46);
  }

  92.5925925926% {
    transform: translateX(-40vw) translateY(11vh) scale(0.54);
  }

  96.2962962963% {
    transform: translateX(19vw) translateY(3vh) scale(0.86);
  }

  100% {
    transform: translateX(35vw) translateY(-45vh) scale(0.73);
  }
}

.firefly:nth-child(13) {
  animation-name: move13;
}

.firefly:nth-child(13)::before {
  animation-duration: 12s;
}

.firefly:nth-child(13)::after {
  animation-duration: 12s, 10857ms;
  animation-delay: 0ms, 8399ms;
}

@keyframes move13 {
  0% {
    transform: translateX(-20vw) translateY(28vh) scale(0.31);
  }

  5.2631578947% {
    transform: translateX(10vw) translateY(-35vh) scale(0.91);
  }

  10.5263157895% {
    transform: translateX(-12vw) translateY(7vh) scale(0.65);
  }

  15.7894736842% {
    transform: translateX(-35vw) translateY(-3vh) scale(0.56);
  }

  21.0526315789% {
    transform: translateX(-24vw) translateY(-16vh) scale(0.35);
  }

  26.3157894737% {
    transform: translateX(0vw) translateY(20vh) scale(0.57);
  }

  31.5789473684% {
    transform: translateX(-45vw) translateY(-3vh) scale(0.77);
  }

  36.8421052632% {
    transform: translateX(37vw) translateY(36vh) scale(0.7);
  }

  42.1052631579% {
    transform: translateX(-9vw) translateY(8vh) scale(0.92);
  }

  47.3684210526% {
    transform: translateX(-14vw) translateY(-41vh) scale(0.29);
  }

  52.6315789474% {
    transform: translateX(-12vw) translateY(-35vh) scale(0.71);
  }

  57.8947368421% {
    transform: translateX(-3vw) translateY(-21vh) scale(0.48);
  }

  63.1578947368% {
    transform: translateX(12vw) translateY(6vh) scale(0.8);
  }

  68.4210526316% {
    transform: translateX(16vw) translateY(-5vh) scale(0.75);
  }

  73.6842105263% {
    transform: translateX(-39vw) translateY(-43vh) scale(0.68);
  }

  78.9473684211% {
    transform: translateX(35vw) translateY(-46vh) scale(0.3);
  }

  84.2105263158% {
    transform: translateX(35vw) translateY(-45vh) scale(0.83);
  }

  89.4736842105% {
    transform: translateX(-8vw) translateY(13vh) scale(0.39);
  }

  94.7368421053% {
    transform: translateX(-12vw) translateY(32vh) scale(0.45);
  }

  100% {
    transform: translateX(-14vw) translateY(42vh) scale(0.67);
  }
}

.firefly:nth-child(14) {
  animation-name: move14;
}

.firefly:nth-child(14)::before {
  animation-duration: 11s;
}

.firefly:nth-child(14)::after {
  animation-duration: 11s, 7829ms;
  animation-delay: 0ms, 5814ms;
}

@keyframes move14 {
  0% {
    transform: translateX(10vw) translateY(-18vh) scale(0.99);
  }

  5.8823529412% {
    transform: translateX(7vw) translateY(18vh) scale(0.53);
  }

  11.7647058824% {
    transform: translateX(-38vw) translateY(3vh) scale(0.96);
  }

  17.6470588235% {
    transform: translateX(22vw) translateY(-38vh) scale(0.49);
  }

  23.5294117647% {
    transform: translateX(8vw) translateY(-38vh) scale(0.41);
  }

  29.4117647059% {
    transform: translateX(34vw) translateY(26vh) scale(0.7);
  }

  35.2941176471% {
    transform: translateX(-7vw) translateY(-3vh) scale(0.52);
  }

  41.1764705882% {
    transform: translateX(-43vw) translateY(45vh) scale(0.43);
  }

  47.0588235294% {
    transform: translateX(47vw) translateY(9vh) scale(0.89);
  }

  52.9411764706% {
    transform: translateX(45vw) translateY(32vh) scale(0.77);
  }

  58.8235294118% {
    transform: translateX(8vw) translateY(16vh) scale(0.64);
  }

  64.7058823529% {
    transform: translateX(-45vw) translateY(-38vh) scale(0.92);
  }

  70.5882352941% {
    transform: translateX(-6vw) translateY(-40vh) scale(0.64);
  }

  76.4705882353% {
    transform: translateX(29vw) translateY(-27vh) scale(0.83);
  }

  82.3529411765% {
    transform: translateX(4vw) translateY(10vh) scale(0.86);
  }

  88.2352941176% {
    transform: translateX(-8vw) translateY(43vh) scale(0.91);
  }

  94.1176470588% {
    transform: translateX(33vw) translateY(10vh) scale(0.57);
  }

  100% {
    transform: translateX(-17vw) translateY(14vh) scale(0.83);
  }
}

.firefly:nth-child(15) {
  animation-name: move15;
}

.firefly:nth-child(15)::before {
  animation-duration: 14s;
}

.firefly:nth-child(15)::after {
  animation-duration: 14s, 8455ms;
  animation-delay: 0ms, 1227ms;
}

@keyframes move15 {
  0% {
    transform: translateX(12vw) translateY(-1vh) scale(0.28);
  }

  4% {
    transform: translateX(6vw) translateY(-14vh) scale(0.61);
  }

  8% {
    transform: translateX(9vw) translateY(-13vh) scale(0.51);
  }

  12% {
    transform: translateX(26vw) translateY(14vh) scale(0.81);
  }

  16% {
    transform: translateX(-32vw) translateY(7vh) scale(0.99);
  }

  20% {
    transform: translateX(19vw) translateY(10vh) scale(0.27);
  }

  24% {
    transform: translateX(-13vw) translateY(-10vh) scale(0.83);
  }

  28% {
    transform: translateX(-35vw) translateY(-2vh) scale(0.39);
  }

  32% {
    transform: translateX(-28vw) translateY(-13vh) scale(0.82);
  }

  36% {
    transform: translateX(-45vw) translateY(28vh) scale(0.48);
  }

  40% {
    transform: translateX(-5vw) translateY(42vh) scale(0.74);
  }

  44% {
    transform: translateX(-30vw) translateY(22vh) scale(0.44);
  }

  48% {
    transform: translateX(15vw) translateY(-21vh) scale(0.55);
  }

  52% {
    transform: translateX(-6vw) translateY(1vh) scale(0.94);
  }

  56% {
    transform: translateX(-26vw) translateY(13vh) scale(0.26);
  }

  60% {
    transform: translateX(44vw) translateY(-49vh) scale(0.67);
  }

  64% {
    transform: translateX(23vw) translateY(-28vh) scale(0.35);
  }

  68% {
    transform: translateX(35vw) translateY(37vh) scale(0.56);
  }

  72% {
    transform: translateX(-4vw) translateY(43vh) scale(0.76);
  }

  76% {
    transform: translateX(-43vw) translateY(43vh) scale(0.56);
  }

  80% {
    transform: translateX(-12vw) translateY(1vh) scale(0.99);
  }

  84% {
    transform: translateX(-26vw) translateY(3vh) scale(0.3);
  }

  88% {
    transform: translateX(46vw) translateY(-9vh) scale(0.55);
  }

  92% {
    transform: translateX(33vw) translateY(-15vh) scale(0.66);
  }

  96% {
    transform: translateX(19vw) translateY(-3vh) scale(0.59);
  }

  100% {
    transform: translateX(-32vw) translateY(-18vh) scale(0.31);
  }
}

@keyframes drift {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes flash {

  0%,
  30%,
  100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw #31ff40;
  }

  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw #31ff40;
  }
}

@media screen and (max-width: 499px) {

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;

  }

  #fontSize2 {
    font-size: 16px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #2ba531 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #196e1e solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    bottom: 10%;
    z-index: 1000;
  }


  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    background-color: rgba(0, 0, 0, 0);
    contain: content;
  }

  .rmbg2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 2%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 100000000;

  }

  input {
    background-color: rgba(0, 30, 70, 0.568);
    border: white 1px solid;
    font-size: 20px;
    padding: 15px;
    color: #00d6fc;
    z-index: 100000000;
  }

  input:focus {
    background-color: #b3e9ff00;
    color: #00d6fc;
  }

  #wlBtn {
    background-color: rgba(0, 30, 70, 0.568);
    border: 1px solid rgb(16, 192, 223);
    color: #ffffff;
    width: 200px;
    z-index: 100000000;
  }

  #wlBtn:hover {
    font-size: 18px;
  }

  .wlLine {
    background-color: #000000;
    padding: 50px;
  }


  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .allWrap {
    background: rgb(70, 63, 101);
    background: radial-gradient(circle, rgba(70, 63, 101, 1) 0%, rgba(18, 19, 38, 1) 46%);
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /*contain: content;*/
  }

  .cont {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .cont2 {
    

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    z-index: 100000000000000;
  }


  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 {
    z-index: 100000000;

  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(0.9);
    }

    60% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  .logo2Intro {
    z-index: 200000000000000000000000000000000;
    width: 75%;
    padding-top: 25vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: heartbeat 2s ease-in-out infinite;
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 80%;
    padding-top: 8vh;
    transition: transform 0.8s;
    z-index: 100000000;
  }

  .in2 img:hover {
    transform: scale(1.3);
  }


  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
  }

  .boxWrap2StoryR {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }

  .boxWrap2StoryT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }


  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    contain: content;
    padding-bottom: 2%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: 2%;
    z-index: 100000000000;
  }

  .boxWrap2SubR {
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap2Sub1 {
    contain: content;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .boxWrap2SubT2 {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .boxWrap2SubT {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #22e2d9;
  }

  .storyH {
    font-size: 200px;
    color: black;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 10px;
    font-style: normal;
    font-family: 'Exo', sans-serif;
  }

  .outer-div {
    width: 500px;
    height: 100px;
    background-color: #fff;
    transition: background-color 1s ease;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 2%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 45px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
  }

  .storyH3 {
    font-size: 35px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
    background: rgb(88, 101, 242);
    background: linear-gradient(180deg, rgb(36, 51, 219) 12%, rgb(67, 3, 185) 100%);
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    cursor: pointer;
    transition: transform .2s;
    margin-top: -15%;
  }

  .storyH3:hover {
    background: rgb(38, 47, 222);
    background: linear-gradient(180deg, rgba(38, 47, 222, 1) 12%, rgba(176, 68, 242, 1) 100%);
  }

  /* Animation */
  @keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  .bg {
    background-color: #17237e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 90%;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutPS {
    width: 90%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutP3 {
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  .aboutP3 p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
  }

  .aboutP p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
  }

  .aboutPDiv {
    background: rgb(251, 46, 249);
    background: linear-gradient(90deg, rgba(251, 46, 249, 1) 0%, rgba(9, 9, 121, 1) 99%);
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    width: 900px;
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 20px;
    font-size: 18px;
    line-height: 40px;
  }

  .aboutPDiv2 {
    background-color: #2a32438a;
    backdrop-filter: blur(5px);
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 20px;
    font-size: 23px;
    line-height: 40px;
    color: white;
  }

  .hl {
    color: #1ee03e;
  }


  .bigLetters {
    font-size: 30px;
  }

  .ironfist {
    margin-top: auto;
    margin-left: -40px;
    z-index: 100000;
  }

  .ironfist img {
    width: 50px;
    height: 50px;

  }

  .storyConAndImg {
    display: flex;
    justify-content: center;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 90%;
    height: 90%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 60%;
    display: flex;
  }


  .facetoface img {
    width: 50%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -10%;
  }

  .footerImg {

    display: flex;
    justify-content: center;
  }

  .footerImg img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
    width: 33.33%;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
       #009218;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 1.5rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 25px;
    display: none;
  }

  .logoUp {
    width: 40px;
    height: 40px;
    margin-top: -5px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 1;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 10%;
    margin-left: 85%;
    margin-top: -17%;
    cursor: pointer;
    transition: transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    animation-name: move-clouds;
    animation-duration: 600s;
    animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    animation-name: move-clouds;
    animation-duration: 200s;
    animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    animation-name: move-clouds;
    animation-duration: 480s;
    animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @keyframes move-clouds {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .line {
    width: 100%;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    display: none;
  }

  .connect2-2-Mob {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 12px #7f8082;

    border: #ffa528 5px groove;
    margin-bottom: 15%;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 22px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 30px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 1000000000000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 1000000000000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background: rgb(0, 120, 235);
    background: linear-gradient(180deg, rgba(0, 120, 235, 1) 0%, rgba(89, 154, 215, 1) 50%, rgba(0, 120, 235, 1) 100%);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: 6px rgb(0, 0, 0);
    border-style: double;
    z-index: 1000000000000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 80px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 25px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(240, 161, 97);
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .rm2Box {
    display: flex;
    justify-content: center;
    margin-right: auto;

  }

  .rm3Box {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .rmbox1 {
    clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    font-size: 25px;
    background: rgb(0, 89, 131);
    background: linear-gradient(95deg, rgba(0, 90, 131, 0.521) 0%, rgba(0, 85, 131, 0.527) 100%);
    backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 170px;
    padding: 30px;
    text-align: center;

  }

  .rmbox2 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    font-size: 20px;
    background-color: #ffffffb0;
    color: rgb(20, 44, 97);
    width: 180px;
    padding: 30px;
    text-align: center;
    backdrop-filter: blur(5px);
  }

  .rmbox1-3 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    text-align: center;

    font-size: 25px;
    background: rgb(0, 89, 131);
    background: linear-gradient(95deg, rgba(0, 90, 131, 0.521) 0%, rgba(0, 85, 131, 0.527) 100%);
    backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 170px;
    padding: 30px;
  }

  .rmbox2-3 {
    font-size: 20px;
    clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    text-align: center;
    background-color: #ffffffb0;
    color: rgb(20, 44, 97);
    width: 180px;
    padding: 25px;
    backdrop-filter: blur(5px);
  }

  .rmConMain {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    margin-top: 80px;
  }

  .scroll-effect-container {
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;

  }

  .scroll-effect {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  }

  .scroll-effect-relic {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
  }

  .scroll-effect-mint {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;

  }

  .scroll-effect-tokenomics {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;

  }

  .scroll-effect-footer {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;

  }


  .scroll-effect-rm {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;

  }


  .scroll-effect-footer {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {
    /*background: rgb(87, 102, 244);
    background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    background: rgb(42, 50, 67);
    background: linear-gradient(180deg, rgba(42, 50, 67, 0.623) 12%, rgba(26, 121, 58, 0.603) 100%);
    backdrop-filter: blur(5px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 1s ease-in-out;
    animation: fill-effect 1s ease-in-out;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate.animate {
    background-size: 100% 100%;

  }

  /*.animateC{
    background: rgb(244, 87, 179);
    background: linear-gradient(270deg, rgb(238, 77, 190) 0%, rgb(139, 8, 89) 51%);  background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 0.5s ease-in-out;
    animation: fill-effect 0.5s ease-in-out;
    font-style: italic;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }*/

  .animateC.animateC {
    background-size: 100% 100%;
  }

  /* Define the walkingNft class */
  .walkingNft {
    width: 55%;
    position: absolute;
    bottom: -0.8%;
    left: 0;
    animation: walk 20s linear infinite;

  }

  /* Define the walk animation */
  @keyframes walk {
    0% {
      transform: translateX(-5vw);
    }

    10% {
      transform: translateX(10vw) rotate(6deg);
    }

    20% {
      transform: translateX(20vw) rotate(0deg);
    }

    30% {
      transform: translateX(30vw) rotate(6deg);
    }

    40% {
      transform: translateX(40vw) rotate(0deg);
    }

    50% {
      transform: translateX(50vw) rotate(6deg);
    }

    60% {
      transform: translateX(60vw) rotate(0deg);
    }

    70% {
      transform: translateX(70vw) rotate(6deg);
    }

    80% {
      transform: translateX(80vw) rotate(0deg);
    }

    90% {
      transform: translateX(90vw) rotate(6deg);
    }

    100% {
      transform: translateX(100vw) rotate(0deg);
    }

  }


  .images2 {
    display: flex;
    justify-content: space-between;
  }

  .images2 img {
    width: 50%;
    z-index: 10000;
  }



  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;

    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    padding-left: 100%;
    z-index: 20000000000000000;
    margin-top: -3.5px;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
    z-index: 1000000000000;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 27px;
    font-weight: 800;
    color: white;
    z-index: 1000000000000;

  }

  .line {
    background-color: rgb(157, 53, 206);
    height: 15vh;
    width: 120vw;
    border-bottom: 12px solid rgb(7, 153, 250);
    border-top: 12px solid rgb(7, 153, 250);
    rotate: 10deg;
    margin-left: -50px;
    display: flex;
    justify-content: space-between;
    margin-top: 15%;
  }

  .line2 {
    background-color: rgb(157, 53, 206);
    height: 15vh;
    width: 120vw;
    border-bottom: 12px solid rgb(7, 153, 250);
    border-top: 12px solid rgb(7, 153, 250);
    rotate: 8deg;
    margin-left: -40px;
    display: flex;
    margin-top: 2%;
    justify-content: space-between;
    margin-bottom: 100px;

  }

  .line2 img {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;

  }

  .line img {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .weapon {
    position: sticky;
    bottom: 0;
    width: 30%;
    margin-top: -15%;
  }

  .potionBottle {
    width: 30%;
    margin-top: -15%;
    z-index: 100000;
    margin-left: 20px;
  }

  .bawl {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 20px;
  }

  .ball {
    width: 28%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 20px;
  }


  @keyframes float {
    0% {
      transform: translate(0, 0);
    }

    50% {
      transform: translate(0, 20px);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  .mashroom {
    width: 30%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 70%;
    rotate: 25deg;
  }

  .lucky {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
  }

  .lucky img {
    width: 20%;
  }

  @keyframes shake {
    0% {
      transform: translate(0, 0) rotate(0);
    }

    10% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      transform: translate(0, 0) rotate(0);
    }
  }

  @keyframes zoom {
    0% {
      transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      transform: scale(1);
      /* End the animation with normal size image */
    }
  }


  .gifImg {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 90vw;
    height: 90vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);

  }

  .gifImg2 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 90vw;
    height: 90vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg3 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 90vw;
    height: 90vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg4 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 90vw;
    height: 90vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifMain {
    background-color: rgba(0, 0, 0, 0);
    margin-top: -10%;
  }



  .bubble-container {
    flex-flow: column;
    display: flex;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;

  }

  #fontSize2 {
    font-size: 16px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #2ba531 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #196e1e solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    bottom: 10%;
    z-index: 1000;
  }


  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    background-color: rgba(0, 0, 0, 0);
    contain: content;
  }

  .rmbg2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 2%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 100000000;

  }

  input {
    background-color: rgba(0, 30, 70, 0.568);
    border: white 1px solid;
    font-size: 20px;
    padding: 15px;
    color: #00d6fc;
    z-index: 100000000;
  }

  input:focus {
    background-color: #b3e9ff00;
    color: #00d6fc;
  }

  #wlBtn {
    background-color: rgba(0, 30, 70, 0.568);
    border: 1px solid rgb(16, 192, 223);
    color: #ffffff;
    width: 200px;
    z-index: 100000000;
  }

  #wlBtn:hover {
    font-size: 18px;
  }

  .wlLine {
    background-color: #000000;
    padding: 50px;
  }


  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .allWrap {
    background: rgb(70, 63, 101);
    background: radial-gradient(circle, rgba(70, 63, 101, 1) 0%, rgba(18, 19, 38, 1) 46%);
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /*contain: content;*/
  }

  .cont {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .cont2 {
    

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    z-index: 100000000000000;
  }


  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 {
    z-index: 100000000;

  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(0.9);
    }

    60% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  .logo2Intro {
    z-index: 200000000000000000000000000000000;
    width: 65%;
    padding-top: 27.9vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: heartbeat 2s ease-in-out infinite;
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    padding-top: 8vh;
    transition: transform 0.8s;
    z-index: 100000000;
  }

  .in2 img:hover {
    transform: scale(1.3);
  }


  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
  }

  .boxWrap2StoryR {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }

  .boxWrap2StoryT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }


  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    contain: content;
    padding-bottom: 2%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: 2%;
    z-index: 100000000000;
  }

  .boxWrap2SubR {
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap2Sub1 {
    contain: content;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .boxWrap2SubT2 {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .boxWrap2SubT {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #22e2d9;
  }

  .storyH {
    font-size: 200px;
    color: black;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 10px;
    font-style: normal;
    font-family: 'Exo', sans-serif;
  }

  .outer-div {
    width: 500px;
    height: 100px;
    background-color: #fff;
    transition: background-color 1s ease;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 2%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 60px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
  }

  .storyH3 {
    font-size: 50px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
    background: rgb(88, 101, 242);
    background: linear-gradient(180deg, rgb(36, 51, 219) 12%, rgb(67, 3, 185) 100%);
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    cursor: pointer;
    transition: transform .2s;
    margin-top: -15%;
  }

  .storyH3:hover {
    background: rgb(38, 47, 222);
    background: linear-gradient(180deg, rgba(38, 47, 222, 1) 12%, rgba(176, 68, 242, 1) 100%);
  }

  /* Animation */
  @keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  .bg {
    background-color: #17237e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 90%;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutPS {
    width: 90%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutP3 {
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  .aboutP3 p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
  }

  .aboutP p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
  }

  .aboutPDiv {
    background: rgb(251, 46, 249);
    background: linear-gradient(90deg, rgba(251, 46, 249, 1) 0%, rgba(9, 9, 121, 1) 99%);
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    width: 900px;
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 20px;
    font-size: 20px;
    line-height: 40px;
  }

  .aboutPDiv2 {
    background-color: #2a32438a;
    backdrop-filter: blur(5px);
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 20px;
    font-size: 25px;
    line-height: 40px;
    color: white;
  }

  .hl {
    color: #1ee03e;
  }


  .bigLetters {
    font-size: 40px;
  }

  .ironfist {
    margin-top: auto;
    margin-left: -40px;
    z-index: 100000;
  }

  .ironfist img {
    width: 60px;
    height: 60px;

  }

  .storyConAndImg {
    display: flex;
    justify-content: center;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 65%;
    height: 65%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 60%;
    display: flex;
  }


  .facetoface img {
    width: 50%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -15%;
  }

  .footerImg {

    display: flex;
    justify-content: center;
  }

  .footerImg img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
    width: 33.33%;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
       #009218;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 1.8rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 25px;
    display: none;
  }

  .logoUp {
    width: 40px;
    height: 40px;
    margin-top: -5px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 1;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 10%;
    margin-left: 85%;
    margin-top: -17%;
    cursor: pointer;
    transition: transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    animation-name: move-clouds;
    animation-duration: 600s;
    animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    animation-name: move-clouds;
    animation-duration: 200s;
    animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    animation-name: move-clouds;
    animation-duration: 480s;
    animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @keyframes move-clouds {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .line {
    width: 100%;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    display: none;
  }

  .connect2-2-Mob {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 12px #7f8082;

    border: #ffa528 5px groove;
    margin-bottom: 15%;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 1000000000000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 1000000000000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background: rgb(0, 120, 235);
    background: linear-gradient(180deg, rgba(0, 120, 235, 1) 0%, rgba(89, 154, 215, 1) 50%, rgba(0, 120, 235, 1) 100%);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: 6px rgb(0, 0, 0);
    border-style: double;
    z-index: 1000000000000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(240, 161, 97);
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .rm2Box {
    display: flex;
    justify-content: center;
    margin-right: auto;

  }

  .rm3Box {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .rmbox1 {
    clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    font-size: 35px;
    background: rgb(0, 89, 131);
    background: linear-gradient(95deg, rgba(0, 90, 131, 0.521) 0%, rgba(0, 85, 131, 0.527) 100%);
    backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 200px;
    padding: 30px;
    text-align: center;

  }

  .rmbox2 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    font-size: 22px;
    background-color: #ffffffb0;
    color: rgb(20, 44, 97);
    width: 250px;
    padding: 25px;
    text-align: center;
    backdrop-filter: blur(5px);
  }

  .rmbox1-3 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    text-align: center;

    font-size: 35px;
    background: rgb(0, 89, 131);
    background: linear-gradient(95deg, rgba(0, 90, 131, 0.521) 0%, rgba(0, 85, 131, 0.527) 100%);
    backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 200px;
    padding: 30px;
  }

  .rmbox2-3 {
    font-size: 22px;
    clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    text-align: center;
    background-color: #ffffffb0;
    color: rgb(20, 44, 97);
    width: 250px;
    padding: 25px;
    backdrop-filter: blur(5px);
  }

  .rmConMain {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    margin-top: 80px;
  }

  .scroll-effect-container {
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;

  }

  .scroll-effect {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  }

  .scroll-effect-relic {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
  }

  .scroll-effect-mint {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;

  }

  .scroll-effect-tokenomics {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;

  }

  .scroll-effect-footer {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;

  }


  .scroll-effect-rm {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;

  }


  .scroll-effect-footer {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {
    /*background: rgb(87, 102, 244);
    background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    background: rgb(42, 50, 67);
    background: linear-gradient(180deg, rgba(42, 50, 67, 0.623) 12%, rgba(26, 121, 58, 0.603) 100%);
    backdrop-filter: blur(5px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 1s ease-in-out;
    animation: fill-effect 1s ease-in-out;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate.animate {
    background-size: 100% 100%;

  }

  /*.animateC{
    background: rgb(244, 87, 179);
    background: linear-gradient(270deg, rgb(238, 77, 190) 0%, rgb(139, 8, 89) 51%);  background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 0.5s ease-in-out;
    animation: fill-effect 0.5s ease-in-out;
    font-style: italic;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }*/

  .animateC.animateC {
    background-size: 100% 100%;
  }

  /* Define the walkingNft class */
  .walkingNft {
    width: 45%;
    position: absolute;
    bottom: -0.8%;
    left: 0;
    animation: walk 20s linear infinite;

  }

  /* Define the walk animation */
  @keyframes walk {
    0% {
      transform: translateX(-5vw);
    }

    10% {
      transform: translateX(10vw) rotate(6deg);
    }

    20% {
      transform: translateX(20vw) rotate(0deg);
    }

    30% {
      transform: translateX(30vw) rotate(6deg);
    }

    40% {
      transform: translateX(40vw) rotate(0deg);
    }

    50% {
      transform: translateX(50vw) rotate(6deg);
    }

    60% {
      transform: translateX(60vw) rotate(0deg);
    }

    70% {
      transform: translateX(70vw) rotate(6deg);
    }

    80% {
      transform: translateX(80vw) rotate(0deg);
    }

    90% {
      transform: translateX(90vw) rotate(6deg);
    }

    100% {
      transform: translateX(100vw) rotate(0deg);
    }

  }


  .images2 {
    display: flex;
    justify-content: space-between;
  }

  .images2 img {
    width: 50%;
    margin-top: -8%;
    z-index: 10000;
  }



  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5rem;

    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    padding-left: 100%;
    z-index: 20000000000000000;
    margin-top: -3.5px;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
    z-index: 1000000000000;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 35px;
    font-weight: 800;
    color: white;
    z-index: 1000000000000;

  }

  .line {
    background-color: rgb(157, 53, 206);
    height: 15vh;
    width: 120vw;
    border-bottom: 12px solid rgb(7, 153, 250);
    border-top: 12px solid rgb(7, 153, 250);
    rotate: 10deg;
    margin-left: -50px;
    display: flex;
    justify-content: space-between;
    margin-top: 15%;
  }

  .line2 {
    background-color: rgb(157, 53, 206);
    height: 15vh;
    width: 120vw;
    border-bottom: 12px solid rgb(7, 153, 250);
    border-top: 12px solid rgb(7, 153, 250);
    rotate: 8deg;
    margin-left: -40px;
    display: flex;
    margin-top: 2%;
    justify-content: space-between;
    margin-bottom: 100px;

  }

  .line2 img {
    width: 25px;
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;

  }

  .line img {
    width: 25px;
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .weapon {
    position: sticky;
    bottom: 0;
    width: 30%;
    margin-top: -15%;
  }

  .potionBottle {
    width: 30%;
    margin-top: -15%;
    z-index: 100000;
    margin-left: 20px;
  }

  .bawl {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 20px;
  }

  .ball {
    width: 28%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 20px;
  }


  @keyframes float {
    0% {
      transform: translate(0, 0);
    }

    50% {
      transform: translate(0, 20px);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  .mashroom {
    width: 30%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 70%;
    rotate: 25deg;
  }

  .lucky {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
  }

  .lucky img {
    width: 20%;
  }

  @keyframes shake {
    0% {
      transform: translate(0, 0) rotate(0);
    }

    10% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      transform: translate(0, 0) rotate(0);
    }
  }

  @keyframes zoom {
    0% {
      transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      transform: scale(1);
      /* End the animation with normal size image */
    }
  }


  .gifImg {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 80vw;
    height: 80vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);

  }

  .gifImg2 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 80vw;
    height: 80vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg3 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 80vw;
    height: 80vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg4 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 80vw;
    height: 80vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifMain {
    background-color: rgba(0, 0, 0, 0);
    margin-top: -10%;
  }



  .bubble-container {
    flex-flow: column;
    display: flex;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;

  }

  #fontSize2 {
    font-size: 16px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #2ba531 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #196e1e solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    bottom: 10%;
    z-index: 1000;
  }


  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    background-color: rgba(0, 0, 0, 0);
    contain: content;
  }

  .rmbg2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 2%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 100000000;

  }

  input {
    background-color: rgba(0, 30, 70, 0.568);
    border: white 1px solid;
    font-size: 20px;
    padding: 15px;
    color: #00d6fc;
    z-index: 100000000;
  }

  input:focus {
    background-color: #b3e9ff00;
    color: #00d6fc;
  }

  #wlBtn {
    background-color: rgba(0, 30, 70, 0.568);
    border: 1px solid rgb(16, 192, 223);
    color: #ffffff;
    width: 200px;
    z-index: 100000000;
  }

  #wlBtn:hover {
    font-size: 18px;
  }

  .wlLine {
    background-color: #000000;
    padding: 50px;
  }


  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .allWrap {
    background: rgb(70, 63, 101);
    background: radial-gradient(circle, rgba(70, 63, 101, 1) 0%, rgba(18, 19, 38, 1) 46%);
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /*contain: content;*/
  }

  .cont {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .cont2 {
    

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    z-index: 100000000000000;
  }


  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 {
    z-index: 100000000;

  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(0.9);
    }

    60% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  .logo2Intro {
    z-index: 200000000000000000000000000000000;
    width: 60%;
    padding-top: 27.9vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: heartbeat 2s ease-in-out infinite;
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding-top: 12vh;
    transition: transform 0.8s;
    z-index: 100000000;
  }

  .in2 img:hover {
    transform: scale(1.3);
  }


  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
  }

  .boxWrap2StoryR {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }

  .boxWrap2StoryT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }


  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    contain: content;
    padding-bottom: 2%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: 2%;
    z-index: 100000000000;
  }

  .boxWrap2SubR {
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap2Sub1 {
    contain: content;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .boxWrap2SubT2 {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .boxWrap2SubT {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #22e2d9;
  }

  .storyH {
    font-size: 200px;
    color: black;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 10px;
    font-style: normal;
    font-family: 'Exo', sans-serif;
  }

  .outer-div {
    width: 500px;
    height: 100px;
    background-color: #fff;
    transition: background-color 1s ease;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 2%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 100px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
  }

  .storyH3 {
    font-size: 75px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
    background: rgb(88, 101, 242);
    background: linear-gradient(180deg, rgb(36, 51, 219) 12%, rgb(67, 3, 185) 100%);
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    cursor: pointer;
    transition: transform .2s;
    margin-top: -15%;
  }

  .storyH3:hover {
    background: rgb(38, 47, 222);
    background: linear-gradient(180deg, rgba(38, 47, 222, 1) 12%, rgba(176, 68, 242, 1) 100%);
  }

  /* Animation */
  @keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  .bg {
    background-color: #17237e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 90%;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutPS {
    width: 90%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutP3 {
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  .aboutP3 p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
  }

  .aboutP p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
  }

  .aboutPDiv {
    background: rgb(251, 46, 249);
    background: linear-gradient(90deg, rgba(251, 46, 249, 1) 0%, rgba(9, 9, 121, 1) 99%);
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    width: 900px;
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 20px;
    font-size: 20px;
    line-height: 40px;
  }

  .aboutPDiv2 {
    background-color: #2a32438a;
    backdrop-filter: blur(5px);
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 20px;
    font-size: 30px;
    line-height: 40px;
    color: white;
  }

  .hl {
    color: #1ee03e;
  }


  .bigLetters {
    font-size: 50px;
  }

  .ironfist {
    margin-top: auto;
    margin-left: -40px;
    z-index: 100000;
  }

  .ironfist img {
    width: 60px;
    height: 60px;

  }

  .storyConAndImg {
    display: flex;
    justify-content: center;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 55%;
    height: 55%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }


  .facetoface img {
    width: 40%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -25%;
  }

  .footerImg {
    display: flex;
    justify-content: center;
  }

  .footerImg img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    width: 33.33vw;
    margin-left: 10px;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }
  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
       #009218;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 1.8rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 25px;
  }

  .logoUp {
    width: 40px;
    height: 40px;
    margin-top: -5px;
  }


  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 65px;
    height: 65px;
    z-index: 1;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 10%;
    margin-left: 90%;
    cursor: pointer;
    transition: transform .4s;
    z-index: 1;
    margin-top: -17%;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    animation-name: move-clouds;
    animation-duration: 600s;
    animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    animation-name: move-clouds;
    animation-duration: 200s;
    animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    animation-name: move-clouds;
    animation-duration: 480s;
    animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @keyframes move-clouds {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .line {
    width: 100%;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    display: none;
  }

  .connect2-2-Mob {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 12px #7f8082;

    border: #ffa528 5px groove;
    margin-bottom: 15%;

  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 1000000000000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 1000000000000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background: rgb(0, 120, 235);
    background: linear-gradient(180deg, rgba(0, 120, 235, 1) 0%, rgba(89, 154, 215, 1) 50%, rgba(0, 120, 235, 1) 100%);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: 6px rgb(0, 0, 0);
    border-style: double;
    z-index: 1000000000000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(240, 161, 97);
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .rm2Box {
    display: flex;
    justify-content: center;
    margin-right: auto;

  }

  .rm3Box {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .rmbox1 {
    clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    font-size: 50px;
    background: rgb(0, 89, 131);
    background: linear-gradient(95deg, rgba(0, 90, 131, 0.521) 0%, rgba(0, 85, 131, 0.527) 100%);
    backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 270px;
    padding: 30px;
    text-align: center;

  }

  .rmbox2 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    font-size: 25px;
    background-color: #ffffffb0;
    color: rgb(20, 44, 97);
    width: 250px;
    padding: 25px;
    text-align: center;
    backdrop-filter: blur(5px);
  }

  .rmbox1-3 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    text-align: center;

    font-size: 50px;
    background: rgb(0, 89, 131);
    background: linear-gradient(95deg, rgba(0, 90, 131, 0.521) 0%, rgba(0, 85, 131, 0.527) 100%);
    backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 270px;
    padding: 30px;
  }

  .rmbox2-3 {
    font-size: 25px;
    clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    text-align: center;
    background-color: #ffffffb0;
    color: rgb(20, 44, 97);
    width: 250px;
    padding: 25px;
    backdrop-filter: blur(5px);
  }

  .rmConMain {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    margin-top: 80px;
  }

  .scroll-effect-container {
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;

  }

  .scroll-effect {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  }

  .scroll-effect-relic {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-mint {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-tokenomics {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-footer {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-rm {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-footer {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {
    /*background: rgb(87, 102, 244);
    background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    background: rgb(42, 50, 67);
    background: linear-gradient(180deg, rgba(42, 50, 67, 0.623) 12%, rgba(26, 121, 58, 0.603) 100%);
    backdrop-filter: blur(5px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 1s ease-in-out;
    animation: fill-effect 1s ease-in-out;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate.animate {
    background-size: 100% 100%;

  }

  /*.animateC{
    background: rgb(244, 87, 179);
    background: linear-gradient(270deg, rgb(238, 77, 190) 0%, rgb(139, 8, 89) 51%);  background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 0.5s ease-in-out;
    animation: fill-effect 0.5s ease-in-out;
    font-style: italic;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }*/

  .animateC.animateC {
    background-size: 100% 100%;

  }

  /* Define the walkingNft class */
  .walkingNft {
    width: 40%;
    position: absolute;
    bottom: -0.8%;
    left: 0;
    animation: walk 20s linear infinite;

  }

  /* Define the walk animation */
  @keyframes walk {
    0% {
      transform: translateX(-5vw);
    }

    10% {
      transform: translateX(10vw) rotate(6deg);
    }

    20% {
      transform: translateX(20vw) rotate(0deg);
    }

    30% {
      transform: translateX(30vw) rotate(6deg);
    }

    40% {
      transform: translateX(40vw) rotate(0deg);
    }

    50% {
      transform: translateX(50vw) rotate(6deg);
    }

    60% {
      transform: translateX(60vw) rotate(0deg);
    }

    70% {
      transform: translateX(70vw) rotate(6deg);
    }

    80% {
      transform: translateX(80vw) rotate(0deg);
    }

    90% {
      transform: translateX(90vw) rotate(6deg);
    }

    100% {
      transform: translateX(100vw) rotate(0deg);
    }

  }


  .images2 {
    display: flex;
    justify-content: space-between;
  }

  .images2 img {
    width: 50%;
    margin-top: -18%;
    z-index: 10000;
  }



  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5rem;

    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    padding-left: 100%;
    z-index: 20000000000000000;
    margin-top: -3.5px;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
    z-index: 1000000000000;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 35px;
    font-weight: 800;
    color: white;
    z-index: 1000000000000;

  }

  .line {
    background-color: rgb(157, 53, 206);
    height: 15vh;
    width: 120vw;
    border-bottom: 12px solid rgb(7, 153, 250);
    border-top: 12px solid rgb(7, 153, 250);
    rotate: 10deg;
    margin-left: -50px;
    display: flex;
    justify-content: space-between;
    margin-top: 15%;
  }

  .line2 {
    background-color: rgb(157, 53, 206);
    height: 15vh;
    width: 120vw;
    border-bottom: 12px solid rgb(7, 153, 250);
    border-top: 12px solid rgb(7, 153, 250);
    rotate: 8deg;
    margin-left: -40px;
    display: flex;
    margin-top: 2%;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .line2 img {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line img {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .weapon {
    position: sticky;
    bottom: 0;
    width: 25%;
    margin-top: -15%;
  }

  .potionBottle {
    width: 23%;
    margin-top: -15%;
    z-index: 100000;
    margin-left: 20px;
  }

  .bawl {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 20px;
  }

  .ball {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 20px;
  }


  @keyframes float {
    0% {
      transform: translate(0, 0);
    }

    50% {
      transform: translate(0, 20px);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  .mashroom {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 70%;
    rotate: 25deg;
  }

  .lucky {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
  }

  .lucky img {
    width: 20%;
  }

  @keyframes shake {
    0% {
      transform: translate(0, 0) rotate(0);
    }

    10% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      transform: translate(0, 0) rotate(0);
    }
  }

  @keyframes zoom {
    0% {
      transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      transform: scale(1);
      /* End the animation with normal size image */
    }
  }


  .gifImg {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);

  }

  .gifImg2 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg3 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg4 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifMain {
    background-color: rgba(0, 0, 0, 0);
    margin-top: -10%;
  }



  .bubble-container {
    flex-flow: column;
    display: flex;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .connect2-2-Mob{
    display: none;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;

  }

  #fontSize2 {
    font-size: 16px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #2ba531 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #196e1e solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    bottom: 10%;
    z-index: 1000;
  }


  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    background-color: rgba(0, 0, 0, 0);
    contain: content;
  }

  .rmbg2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 2%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 100000000;

  }

  input {
    background-color: rgba(0, 30, 70, 0.568);
    border: white 1px solid;
    font-size: 20px;
    padding: 15px;
    color: #00d6fc;
    z-index: 100000000;
  }

  input:focus {
    background-color: #b3e9ff00;
    color: #00d6fc;
  }

  #wlBtn {
    background-color: rgba(0, 30, 70, 0.568);
    border: 1px solid rgb(16, 192, 223);
    color: #ffffff;
    width: 200px;
    z-index: 100000000;
  }

  #wlBtn:hover {
    font-size: 18px;
  }

  .wlLine {
    background-color: #000000;
    padding: 50px;
  }


  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .allWrap {
    background: rgb(70, 63, 101);
    background: radial-gradient(circle, rgba(70, 63, 101, 1) 0%, rgba(18, 19, 38, 1) 46%);
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /*contain: content;*/
  }

  .cont {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .cont2 {
    

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    z-index: 100000000000000;
  }


  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 {
    z-index: 100000000;

  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(0.9);
    }

    60% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  .logo2Intro {
    z-index: 200000000000000000000000000000000;
    width: 40%;
    padding-top: 27.9vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: heartbeat 2s ease-in-out infinite;
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    padding-top: 12vh;
    transition: transform 0.8s;
    z-index: 100000000;
  }

  .in2 img:hover {
    transform: scale(1.3);
  }


  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
  }

  .boxWrap2StoryR {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }

  .boxWrap2StoryT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }


  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    contain: content;
    padding-bottom: 2%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: 2%;
    z-index: 100000000000;
  }

  .boxWrap2SubR {
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap2Sub1 {
    contain: content;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .boxWrap2SubT2 {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .boxWrap2SubT {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #22e2d9;
  }

  .storyH {
    font-size: 200px;
    color: black;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 10px;
    font-style: normal;
    font-family: 'Exo', sans-serif;
  }

  .outer-div {
    width: 500px;
    height: 100px;
    background-color: #fff;
    transition: background-color 1s ease;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 2%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 130px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
  }

  .storyH3 {
    font-size: 85px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
    background: rgb(88, 101, 242);
    background: linear-gradient(180deg, rgb(36, 51, 219) 12%, rgb(67, 3, 185) 100%);
    width: 650px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    cursor: pointer;
    transition: transform .2s;
    margin-top: -15%;
  }

  .storyH3:hover {
    background: rgb(38, 47, 222);
    background: linear-gradient(180deg, rgba(38, 47, 222, 1) 12%, rgba(176, 68, 242, 1) 100%);
  }

  /* Animation */
  @keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  .bg {
    background-color: #17237e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 45%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutPS {
    width: 45%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutP3 {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    margin-bottom: 50px;
  }

  .aboutP3 p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
  }

  .aboutP p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
  }

  .aboutPDiv {
    background: rgb(251, 46, 249);
    background: linear-gradient(90deg, rgba(251, 46, 249, 1) 0%, rgba(9, 9, 121, 1) 99%);
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    width: 900px;
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 20px;
    font-size: 20px;
    line-height: 40px;
  }

  .aboutPDiv2 {
    background-color: #2a32438a;
    backdrop-filter: blur(5px);
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 20px;
    font-size: 30px;
    line-height: 40px;
    color: white;
  }

  .hl {
    color: #1ee03e;
  }


  .bigLetters {
    font-size: 50px;
  }

  .ironfist {
    margin-top: auto;
    margin-left: -40px;
    z-index: 100000;
  }

  .ironfist img {
    width: 60px;
    height: 60px;

  }

  .storyConAndImg {
    display: flex;
    justify-content: center;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 45%;
    height: 45%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }


  .facetoface img {
    width: 30%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -30%;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: transform .2s;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    width: 33.33vw;
    margin-left: 10px;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }


  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
       #009218;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 1.8rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 35px;
  }

  .logoUp {
    width: 50px;
    height: 50px;
    margin-top: -8px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 65px;
    height: 65px;
    z-index: 1;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 92%;
    margin-top: -8%;
    cursor: pointer;
    transition: transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    animation-name: move-clouds;
    animation-duration: 600s;
    animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    animation-name: move-clouds;
    animation-duration: 200s;
    animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    animation-name: move-clouds;
    animation-duration: 480s;
    animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @keyframes move-clouds {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .line {
    width: 100%;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 12px #7f8082;

    border: #ffa528 5px groove;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 1000000000000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 1000000000000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background: rgb(0, 120, 235);
    background: linear-gradient(180deg, rgba(0, 120, 235, 1) 0%, rgba(89, 154, 215, 1) 50%, rgba(0, 120, 235, 1) 100%);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: 6px rgb(0, 0, 0);
    border-style: double;
    z-index: 1000000000000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(240, 161, 97);
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .rm2Box {
    display: flex;
    justify-content: center;
    margin-right: auto;

  }

  .rm3Box {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .rmbox1 {
    clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    font-size: 70px;
    background: rgb(0, 89, 131);
    background: linear-gradient(95deg, rgba(0, 90, 131, 0.521) 0%, rgba(0, 85, 131, 0.527) 100%);
    backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 350px;
    padding: 30px;
    text-align: center;

  }

  .rmbox2 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    font-size: 30px;
    background-color: #ffffffb0;
    color: rgb(20, 44, 97);
    width: 370px;
    padding: 25px;
    text-align: center;
    backdrop-filter: blur(5px);
  }

  .rmbox1-3 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    text-align: center;

    font-size: 70px;
    background: rgb(0, 89, 131);
    background: linear-gradient(95deg, rgba(0, 90, 131, 0.521) 0%, rgba(0, 85, 131, 0.527) 100%);
    backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 350px;
    padding: 30px;
  }

  .rmbox2-3 {
    font-size: 30px;
    clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    text-align: center;
    background-color: #ffffffb0;
    color: rgb(20, 44, 97);
    width: 370px;
    padding: 25px;
    backdrop-filter: blur(5px);
  }

  .rmConMain {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    margin-top: 80px;
  }

  .scroll-effect-container {
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;

  }

  .scroll-effect {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  }

  .scroll-effect-relic {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-mint {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-tokenomics {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-footer {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-rm {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-footer {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {
    /*background: rgb(87, 102, 244);
    background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    background: rgb(42, 50, 67);
    background: linear-gradient(180deg, rgba(42, 50, 67, 0.623) 12%, rgba(26, 121, 58, 0.603) 100%);
    backdrop-filter: blur(5px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 1s ease-in-out;
    animation: fill-effect 1s ease-in-out;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate.animate {
    background-size: 100% 100%;

  }

  /*.animateC{
    background: rgb(244, 87, 179);
    background: linear-gradient(270deg, rgb(238, 77, 190) 0%, rgb(139, 8, 89) 51%);  background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 0.5s ease-in-out;
    animation: fill-effect 0.5s ease-in-out;
    font-style: italic;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }*/

  .animateC.animateC {
    background-size: 100% 100%;

  }

  /* Define the walkingNft class */
  .walkingNft {
    width: 30%;
    position: absolute;
    bottom: -0.8%;
    left: 0;
    animation: walk 20s linear infinite;

  }

  /* Define the walk animation */
  @keyframes walk {
    0% {
      transform: translateX(-5vw);
    }

    10% {
      transform: translateX(10vw) rotate(6deg);
    }

    20% {
      transform: translateX(20vw) rotate(0deg);
    }

    30% {
      transform: translateX(30vw) rotate(6deg);
    }

    40% {
      transform: translateX(40vw) rotate(0deg);
    }

    50% {
      transform: translateX(50vw) rotate(6deg);
    }

    60% {
      transform: translateX(60vw) rotate(0deg);
    }

    70% {
      transform: translateX(70vw) rotate(6deg);
    }

    80% {
      transform: translateX(80vw) rotate(0deg);
    }

    90% {
      transform: translateX(90vw) rotate(6deg);
    }

    100% {
      transform: translateX(100vw) rotate(0deg);
    }

  }


  .images2 {
    display: flex;
    justify-content: space-between;
  }

  .images2 img {
    width: 43%;
    margin-top: -30%;
    z-index: 10000;
  }



  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5rem;

    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    padding-left: 100%;
    z-index: 20000000000000000;
    margin-top: -3.5px;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
    z-index: 1000000000000;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 35px;
    font-weight: 800;
    color: white;
    z-index: 1000000000000;

  }

  .line {
    background-color: rgb(157, 53, 206);
    height: 20vh;
    width: 120vw;
    border-bottom: 12px solid rgb(7, 153, 250);
    border-top: 12px solid rgb(7, 153, 250);
    rotate: 10deg;
    margin-left: -50px;
    display: flex;
    justify-content: space-between;
    margin-top: 15%;
  }

  .line2 {
    background-color: rgb(157, 53, 206);
    height: 20vh;
    width: 120vw;
    border-bottom: 12px solid rgb(7, 153, 250);
    border-top: 12px solid rgb(7, 153, 250);
    rotate: 8deg;
    margin-left: -40px;
    display: flex;
    margin-top: 2%;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .line2 img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .weapon {
    position: sticky;
    bottom: 0;
    width: 25%;
    margin-top: -15%;
  }

  .potionBottle {
    width: 23%;
    margin-top: -15%;
    z-index: 100000;
    margin-left: 20px;
  }

  .bawl {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 20px;
  }

  .ball {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 20px;
  }


  @keyframes float {
    0% {
      transform: translate(0, 0);
    }

    50% {
      transform: translate(0, 20px);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  .mashroom {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 70%;
    rotate: 25deg;
  }

  .lucky {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
  }

  .lucky img {
    width: 20%;
  }

  @keyframes shake {
    0% {
      transform: translate(0, 0) rotate(0);
    }

    10% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      transform: translate(0, 0) rotate(0);
    }
  }

  @keyframes zoom {
    0% {
      transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      transform: scale(1);
      /* End the animation with normal size image */
    }
  }


  .gifImg {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);

  }

  .gifImg2 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg3 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg4 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifMain {
    background-color: rgba(0, 0, 0, 0);
    margin-top: -10%;
  }



  .bubble-container {
    flex-flow: column;
    display: flex;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .connect2-2-Mob{
    display: none;
  }
  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    bottom: 10%;
    z-index: 1000;
  }


  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    background-color: rgba(0, 0, 0, 0);
    contain: content;
  }

  .rmbg2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 2%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 100000000;

  }

  input {
    background-color: rgba(0, 30, 70, 0.568);
    border: white 1px solid;
    font-size: 20px;
    padding: 15px;
    color: #00d6fc;
    z-index: 100000000;
  }

  input:focus {
    background-color: #b3e9ff00;
    color: #00d6fc;
  }

  #wlBtn {
    background-color: rgba(0, 30, 70, 0.568);
    border: 1px solid rgb(16, 192, 223);
    color: #ffffff;
    width: 200px;
    z-index: 100000000;
  }

  #wlBtn:hover {
    font-size: 18px;
  }

  .wlLine {
    background-color: #000000;
    padding: 50px;
  }


  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background: rgb(70, 63, 101);
    background: radial-gradient(circle, rgba(70, 63, 101, 1) 0%, rgba(18, 19, 38, 1) 46%);
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /*contain: content;*/
  }

  .cont {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .cont2 {
    

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    z-index: 100000000000000;
  }


  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 {
    z-index: 100000000;

  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(0.9);
    }

    60% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  .logo2Intro {
    z-index: 200000000000000000000000000000000;
    width: 40%;
    padding-top: 27.9vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: heartbeat 2s ease-in-out infinite;
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    padding-top: 12vh;
    transition: transform 0.8s;
    z-index: 100000000;
  }

  .in2 img:hover {
    transform: scale(1.3);
  }


  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;*/
    /*background: rgb(12, 22, 42);
  background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
  }

  .boxWrap2StoryR {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;*/
    /*background: rgb(12, 22, 42);
  background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }

  .boxWrap2StoryT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;*/
    /*background: rgb(12, 22, 42);
  background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }


  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    contain: content;
    padding-bottom: 2%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: 2%;
    z-index: 100000000000;
  }

  .boxWrap2SubR {
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap2Sub1 {
    contain: content;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .boxWrap2SubT2 {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .boxWrap2SubT {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #22e2d9;
  }

  .storyH {
    font-size: 200px;
    color: black;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 10px;
    font-style: normal;
    font-family: 'Exo', sans-serif;
  }

  .outer-div {
    width: 500px;
    height: 100px;
    background-color: #fff;
    transition: background-color 1s ease;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 2%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 175px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
  }

  .storyH3 {
    font-size: 100px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
    background: rgb(88, 101, 242);
    background: linear-gradient(180deg, rgb(36, 51, 219) 12%, rgb(67, 3, 185) 100%);
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    cursor: pointer;
    transition: transform .2s;
    margin-top: -15%;
  }

  .storyH3:hover {
    background: rgb(38, 47, 222);
    background: linear-gradient(180deg, rgba(38, 47, 222, 1) 12%, rgba(176, 68, 242, 1) 100%);
  }

  /* Animation */
  @keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  .bg {
    background-color: #17237e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 45%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutPS {
    width: 45%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutP3 {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    margin-bottom: 50px;
  }

  .aboutP3 p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 23px;
  }

  .aboutP p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 23px;
  }

  .aboutPDiv {
    background: rgb(251, 46, 249);
    background: linear-gradient(90deg, rgba(251, 46, 249, 1) 0%, rgba(9, 9, 121, 1) 99%);
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    width: 900px;
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 20px;
    font-size: 20px;
    line-height: 40px;
  }

  .aboutPDiv2 {
    background-color: #2a32438a;
    backdrop-filter: blur(5px);
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 20px;
    font-size: 35px;
    line-height: 40px;
    color: white;
  }

  .hl {
    color: #1ee03e;
  }


  .bigLetters {
    font-size: 55px;
  }

  .ironfist {
    margin-top: auto;
    margin-left: -40px;
    z-index: 100000;
  }

  .ironfist img {
    width: 60px;
    height: 60px;

  }

  .storyConAndImg {
    display: flex;
    justify-content: center;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 45%;
    height: 45%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }


  .facetoface img {
    width: 30%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -27%;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: transform .2s;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }
  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
       #009218;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 1.8rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 35px;
  }

  .logoUp {
    width: 50px;
    height: 50px;
    margin-top: -8px;
  }
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 65px;
    height: 65px;
    z-index: 1;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 92%;
    margin-top: -8%;
    cursor: pointer;
    transition: transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    animation-name: move-clouds;
    animation-duration: 600s;
    animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    animation-name: move-clouds;
    animation-duration: 200s;
    animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    animation-name: move-clouds;
    animation-duration: 480s;
    animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @keyframes move-clouds {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .line {
    width: 100%;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 12px #7f8082;

    border: #ffa528 5px groove;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 1000000000000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 1000000000000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background: rgb(0, 120, 235);
    background: linear-gradient(180deg, rgba(0, 120, 235, 1) 0%, rgba(89, 154, 215, 1) 50%, rgba(0, 120, 235, 1) 100%);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: 6px rgb(0, 0, 0);
    border-style: double;
    z-index: 1000000000000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(240, 161, 97);
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .rm2Box {
    display: flex;
    justify-content: center;
    margin-right: auto;

  }

  .rm3Box {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .rmbox1 {
    clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    font-size: 80px;
    background: rgb(0, 89, 131);
    background: linear-gradient(95deg, rgba(0, 90, 131, 0.521) 0%, rgba(0, 85, 131, 0.527) 100%);
    backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 400px;
    padding: 30px;
    text-align: center;

  }

  .rmbox2 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    font-size: 35px;
    background-color: #ffffffb0;
    color: rgb(20, 44, 97);
    width: 400px;
    padding: 25px;
    text-align: center;
    backdrop-filter: blur(5px);
  }

  .rmbox1-3 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    text-align: center;

    font-size: 80px;
    background: rgb(0, 89, 131);
    background: linear-gradient(95deg, rgba(0, 90, 131, 0.521) 0%, rgba(0, 85, 131, 0.527) 100%);
    backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 400px;
    padding: 30px;
  }

  .rmbox2-3 {
    font-size: 35px;
    clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    text-align: center;
    background-color: #ffffffb0;
    color: rgb(20, 44, 97);
    width: 400px;
    padding: 25px;
    backdrop-filter: blur(5px);
  }

  .rmConMain {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    margin-top: 80px;
  }

  .scroll-effect-container {
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;

  }

  .scroll-effect {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  }

  .scroll-effect-relic {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-mint {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-tokenomics {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-footer {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-rm {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-footer {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {
    /*background: rgb(87, 102, 244);
  background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    background: rgb(42, 50, 67);
    background: linear-gradient(180deg, rgba(42, 50, 67, 0.623) 12%, rgba(26, 121, 58, 0.603) 100%);
    backdrop-filter: blur(5px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 1s ease-in-out;
    animation: fill-effect 1s ease-in-out;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate.animate {
    background-size: 100% 100%;

  }

  /*.animateC{
  background: rgb(244, 87, 179);
  background: linear-gradient(270deg, rgb(238, 77, 190) 0%, rgb(139, 8, 89) 51%);  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 0.5s ease-in-out;
  animation: fill-effect 0.5s ease-in-out;
  font-style: italic;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}*/

  .animateC.animateC {
    background-size: 100% 100%;

  }

  /* Define the walkingNft class */
  .walkingNft {
    width: 30%;
    position: absolute;
    bottom: -0.8%;
    left: 0;
    animation: walk 20s linear infinite;

  }

  /* Define the walk animation */
  @keyframes walk {
    0% {
      transform: translateX(-5vw);
    }

    10% {
      transform: translateX(10vw) rotate(6deg);
    }

    20% {
      transform: translateX(20vw) rotate(0deg);
    }

    30% {
      transform: translateX(30vw) rotate(6deg);
    }

    40% {
      transform: translateX(40vw) rotate(0deg);
    }

    50% {
      transform: translateX(50vw) rotate(6deg);
    }

    60% {
      transform: translateX(60vw) rotate(0deg);
    }

    70% {
      transform: translateX(70vw) rotate(6deg);
    }

    80% {
      transform: translateX(80vw) rotate(0deg);
    }

    90% {
      transform: translateX(90vw) rotate(6deg);
    }

    100% {
      transform: translateX(100vw) rotate(0deg);
    }

  }


  .images2 {
    display: flex;
    justify-content: space-between;
  }

  .images2 img {
    width: 43%;
    margin-top: -30%;
    z-index: 10000;
  }



  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5rem;

    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    padding-left: 100%;
    z-index: 20000000000000000;
    margin-top: -3.5px;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
    z-index: 1000000000000;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 35px;
    font-weight: 800;
    color: white;
    z-index: 1000000000000;

  }

  .line {
    background-color: rgb(157, 53, 206);
    height: 20vh;
    width: 120vw;
    border-bottom: 12px solid rgb(7, 153, 250);
    border-top: 12px solid rgb(7, 153, 250);
    rotate: 10deg;
    margin-left: -50px;
    display: flex;
    justify-content: space-between;
    margin-top: 15%;
  }

  .line2 {
    background-color: rgb(157, 53, 206);
    height: 20vh;
    width: 120vw;
    border-bottom: 12px solid rgb(7, 153, 250);
    border-top: 12px solid rgb(7, 153, 250);
    rotate: 8deg;
    margin-left: -40px;
    display: flex;
    margin-top: 2%;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .line2 img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .weapon {
    position: sticky;
    bottom: 0;
    width: 25%;
    margin-top: -15%;
  }

  .potionBottle {
    width: 23%;
    margin-top: -15%;
    z-index: 100000;
    margin-left: 20px;
  }

  .bawl {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 20px;
  }

  .ball {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 20px;
  }


  @keyframes float {
    0% {
      transform: translate(0, 0);
    }

    50% {
      transform: translate(0, 20px);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  .mashroom {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 70%;
    rotate: 25deg;
  }

  .lucky {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
  }

  .lucky img {
    width: 20%;
  }

  @keyframes shake {
    0% {
      transform: translate(0, 0) rotate(0);
    }

    10% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      transform: translate(0, 0) rotate(0);
    }
  }

  @keyframes zoom {
    0% {
      transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      transform: scale(1);
      /* End the animation with normal size image */
    }
  }


  .gifImg {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);

  }

  .gifImg2 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg3 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg4 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifMain {
    background-color: rgba(0, 0, 0, 0);
    margin-top: -10%;
  }



  .bubble-container {
    flex-flow: column;
    display: flex;
  }
}

@media screen and (min-width: 1920px) {
  .connect2-2-Mob{
    display: none;
  }
  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    bottom: 10%;
    z-index: 1000;
  }


  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    background-color: rgba(0, 0, 0, 0);
    contain: content;
  }

  .rmbg2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 2%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 100000000;

  }

  input {
    background-color: rgba(0, 30, 70, 0.568);
    border: white 1px solid;
    font-size: 20px;
    padding: 15px;
    color: #00d6fc;
    z-index: 100000000;
  }

  input:focus {
    background-color: #b3e9ff00;
    color: #00d6fc;
  }

  #wlBtn {
    background-color: rgba(0, 30, 70, 0.568);
    border: 1px solid rgb(16, 192, 223);
    color: #ffffff;
    width: 200px;
    z-index: 100000000;
  }

  #wlBtn:hover {
    font-size: 18px;
  }

  .wlLine {
    background-color: #000000;
    padding: 50px;
  }


  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background: rgb(70, 63, 101);
    background: radial-gradient(circle, rgba(70, 63, 101, 1) 0%, rgba(18, 19, 38, 1) 46%);
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /*contain: content;*/
  }

  .cont {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .cont2 {
    

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    z-index: 100000000000000;
  }


  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 {
    z-index: 100000000;

  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(0.9);
    }

    60% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  .logo2Intro {
    z-index: 200000000000000000000000000000000;
    width: 40%;
    padding-top: 27.9vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: heartbeat 2s ease-in-out infinite;
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    padding-top: 12vh;
    transition: transform 0.8s;
    z-index: 100000000;
  }

  .in2 img:hover {
    transform: scale(1.3);
  }


  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
  }

  .boxWrap2StoryR {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }

  .boxWrap2StoryT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }


  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    contain: content;
    padding-bottom: 2%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: 2%;
    z-index: 100000000000;
  }

  .boxWrap2SubR {
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap2Sub1 {
    contain: content;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .boxWrap2SubT2 {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .boxWrap2SubT {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #22e2d9;
  }

  .storyH {
    font-size: 200px;
    color: black;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 10px;
    font-style: normal;
    font-family: 'Exo', sans-serif;
  }

  .outer-div {
    width: 500px;
    height: 100px;
    background-color: #fff;
    transition: background-color 1s ease;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 2%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 250px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
  }

  .storyH3 {
    font-size: 130px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
    background: rgb(88, 101, 242);
    background: linear-gradient(180deg, rgb(36, 51, 219) 12%, rgb(67, 3, 185) 100%);
    width: 850px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    cursor: pointer;
    transition: transform .2s;
    margin-top: -15%;
  }

  .storyH3:hover {
    background: rgb(38, 47, 222);
    background: linear-gradient(180deg, rgba(38, 47, 222, 1) 12%, rgba(176, 68, 242, 1) 100%);
  }

  /* Animation */
  @keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  .bg {
    background-color: #17237e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 45%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutPS {
    width: 45%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutP3 {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    margin-bottom: 50px;
  }

  .aboutP3 p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 35px;
  }

  .aboutP p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 35px;
  }

  .aboutPDiv {
    background: rgb(251, 46, 249);
    background: linear-gradient(90deg, rgba(251, 46, 249, 1) 0%, rgba(9, 9, 121, 1) 99%);
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    width: 900px;
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 20px;
    font-size: 28px;
    line-height: 55px;
  }

  .aboutPDiv2 {
    background-color: #2a32438a;
    backdrop-filter: blur(5px);
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    margin-bottom: 17px;
    margin-top: 17px;
    padding: 20px;
    font-size: 50px;
    line-height: 70px;
    color: white;
  }

  .hl {
    color: #1ee03e;
  }


  .bigLetters {
    font-size: 75px;
  }

  .ironfist {
    margin-top: auto;
    margin-left: -40px;
    z-index: 100000;
  }

  .ironfist img {
    width: 80px;
    height: 80px;

  }

  .storyConAndImg {
    display: flex;
    justify-content: center;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 45%;
    height: 45%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }


  .facetoface img {
    width: 30%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -27%;
  }

  .footerImg {
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: transform .2s;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

 
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 25px;
    padding-top: 25px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .connect div {
    margin-left: 25px;
    margin-right: 25px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 45px;
    height: 45px;
    margin-left: 15px;
    margin-right: 15px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 28px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
       #009218;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 2.5rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 50px;
  }

  .logoUp {
    width: 70px;
    height: 70px;
    margin-top: -10px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 65px;
    height: 65px;
    z-index: 1;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 92%;
    margin-top: -8%;
    cursor: pointer;
    transition: transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    animation-name: move-clouds;
    animation-duration: 600s;
    animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    animation-name: move-clouds;
    animation-duration: 200s;
    animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    animation-name: move-clouds;
    animation-duration: 480s;
    animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @keyframes move-clouds {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .line {
    width: 100%;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;

    border: #ffa528 10px groove;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 90px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 35px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 30px;
    margin-right: 30px;
    padding: 5px;
    width: 95px;
    height: 95px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 4px solid rgb(255, 255, 255);
    z-index: 1000000000000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 60px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 1000000000000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background: rgb(0, 120, 235);
    background: linear-gradient(180deg, rgba(0, 120, 235, 1) 0%, rgba(89, 154, 215, 1) 50%, rgba(0, 120, 235, 1) 100%);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 40px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: 6px rgb(0, 0, 0);
    border-style: double;
    z-index: 1000000000000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 120px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(240, 161, 97);
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .rm2Box {
    display: flex;
    justify-content: center;
    margin-right: auto;

  }

  .rm3Box {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .rmbox1 {
    clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    font-size: 110px;
    background: rgb(0, 89, 131);
    background: linear-gradient(95deg, rgba(0, 90, 131, 0.521) 0%, rgba(0, 85, 131, 0.527) 100%);
    backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 550px;
    padding: 30px;
    text-align: center;

  }

  .rmbox2 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    font-size: 45px;
    background-color: #ffffffb0;
    color: rgb(20, 44, 97);
    width: 400px;
    padding: 25px;
    text-align: center;
    backdrop-filter: blur(5px);
  }

  .rmbox1-3 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    text-align: center;

    font-size: 110px;
    background: rgb(0, 89, 131);
    background: linear-gradient(95deg, rgba(0, 90, 131, 0.521) 0%, rgba(0, 85, 131, 0.527) 100%);
    backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 550px;
    padding: 30px;
  }

  .rmbox2-3 {
    font-size: 45px;
    clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    text-align: center;
    background-color: #ffffffb0;
    color: rgb(20, 44, 97);
    width: 400px;
    padding: 25px;
    backdrop-filter: blur(5px);
  }

  .rmConMain {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    margin-top: 80px;
  }

  .scroll-effect-container {
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;

  }

  .scroll-effect {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  }

  .scroll-effect-relic {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-mint {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-tokenomics {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-footer {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-rm {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-footer {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {
    /*background: rgb(87, 102, 244);
    background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    background: rgb(42, 50, 67);
    background: linear-gradient(180deg, rgba(42, 50, 67, 0.623) 12%, rgba(26, 121, 58, 0.603) 100%);
    backdrop-filter: blur(5px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 1s ease-in-out;
    animation: fill-effect 1s ease-in-out;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate.animate {
    background-size: 100% 100%;

  }

  /*.animateC{
    background: rgb(244, 87, 179);
    background: linear-gradient(270deg, rgb(238, 77, 190) 0%, rgb(139, 8, 89) 51%);  background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 0.5s ease-in-out;
    animation: fill-effect 0.5s ease-in-out;
    font-style: italic;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }*/

  .animateC.animateC {
    background-size: 100% 100%;

  }

  /* Define the walkingNft class */
  .walkingNft {
    width: 30%;
    position: absolute;
    bottom: -0.8%;
    left: 0;
    animation: walk 20s linear infinite;

  }

  /* Define the walk animation */
  @keyframes walk {
    0% {
      transform: translateX(-5vw);
    }

    10% {
      transform: translateX(10vw) rotate(6deg);
    }

    20% {
      transform: translateX(20vw) rotate(0deg);
    }

    30% {
      transform: translateX(30vw) rotate(6deg);
    }

    40% {
      transform: translateX(40vw) rotate(0deg);
    }

    50% {
      transform: translateX(50vw) rotate(6deg);
    }

    60% {
      transform: translateX(60vw) rotate(0deg);
    }

    70% {
      transform: translateX(70vw) rotate(6deg);
    }

    80% {
      transform: translateX(80vw) rotate(0deg);
    }

    90% {
      transform: translateX(90vw) rotate(6deg);
    }

    100% {
      transform: translateX(100vw) rotate(0deg);
    }

  }


  .images2 {
    display: flex;
    justify-content: space-between;
  }

  .images2 img {
    width: 43%;
    margin-top: -30%;
    z-index: 10000;
  }



  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 7rem;

    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    padding-left: 100%;
    z-index: 20000000000000000;
    margin-top: -3.5px;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 7rem;
    line-height: 7rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
    z-index: 1000000000000;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 50px;
    font-weight: 800;
    color: white;
    z-index: 1000000000000;

  }

  .line {
    background-color: rgb(157, 53, 206);
    height: 17vh;
    width: 120vw;
    border-bottom: 12px solid rgb(7, 153, 250);
    border-top: 12px solid rgb(7, 153, 250);
    rotate: 10deg;
    margin-left: -50px;
    display: flex;
    justify-content: space-between;
    margin-top: 15%;
  }

  .line2 {
    background-color: rgb(157, 53, 206);
    height: 17vh;
    width: 120vw;
    border-bottom: 12px solid rgb(7, 153, 250);
    border-top: 12px solid rgb(7, 153, 250);
    rotate: 8deg;
    margin-left: -40px;
    display: flex;
    margin-top: 4%;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .line2 img {
    width: 75px;
    height: 75px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line img {
    width: 75px;
    height: 75px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .weapon {
    position: sticky;
    bottom: 0;
    width: 25%;
    margin-top: -15%;
  }

  .potionBottle {
    width: 23%;
    margin-top: -15%;
    z-index: 100000;
    margin-left: 20px;
  }

  .bawl {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 20px;
  }

  .ball {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 20px;
  }


  @keyframes float {
    0% {
      transform: translate(0, 0);
    }

    50% {
      transform: translate(0, 20px);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  .mashroom {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 70%;
    rotate: 25deg;
  }

  .lucky {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
  }

  .lucky img {
    width: 20%;
  }

  @keyframes shake {
    0% {
      transform: translate(0, 0) rotate(0);
    }

    10% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      transform: translate(0, 0) rotate(0);
    }
  }

  @keyframes zoom {
    0% {
      transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      transform: scale(1);
      /* End the animation with normal size image */
    }
  }


  .gifImg {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);

  }

  .gifImg2 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg3 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg4 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifMain {
    background-color: rgba(0, 0, 0, 0);
    margin-top: -10%;
  }



  .bubble-container {
    flex-flow: column;
    display: flex;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .connect2-2-Mob{
    display: none;
  }
  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    bottom: 10%;
    z-index: 1000;
  }


  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    background-color: rgba(0, 0, 0, 0);
    contain: content;
  }

  .rmbg2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 2%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 100000000;

  }

  input {
    background-color: rgba(0, 30, 70, 0.568);
    border: white 1px solid;
    font-size: 20px;
    padding: 15px;
    color: #00d6fc;
    z-index: 100000000;
  }

  input:focus {
    background-color: #b3e9ff00;
    color: #00d6fc;
  }

  #wlBtn {
    background-color: rgba(0, 30, 70, 0.568);
    border: 1px solid rgb(16, 192, 223);
    color: #ffffff;
    width: 200px;
    z-index: 100000000;
  }

  #wlBtn:hover {
    font-size: 18px;
  }

  .wlLine {
    background-color: #000000;
    padding: 50px;
  }


  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background: rgb(70, 63, 101);
    background: radial-gradient(circle, rgba(70, 63, 101, 1) 0%, rgba(18, 19, 38, 1) 46%);
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /*contain: content;*/
  }

  .cont {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .cont2 {
    

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    z-index: 100000000000000;
  }


  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 {
    z-index: 100000000;

  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(0.9);
    }

    60% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  .logo2Intro {
    z-index: 200000000000000000000000000000000;
    width: 40%;
    padding-top: 27.9vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: heartbeat 2s ease-in-out infinite;
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    padding-top: 12vh;
    transition: transform 0.8s;
    z-index: 100000000;
  }

  .in2 img:hover {
    transform: scale(1.3);
  }


  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
  }

  .boxWrap2StoryR {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }

  .boxWrap2StoryT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }


  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    contain: content;
    padding-bottom: 2%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: 2%;
    z-index: 100000000000;
  }

  .boxWrap2SubR {
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap2Sub1 {
    contain: content;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .boxWrap2SubT2 {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .boxWrap2SubT {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #22e2d9;
  }

  .storyH {
    font-size: 200px;
    color: black;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 10px;
    font-style: normal;
    font-family: 'Exo', sans-serif;
  }

  .outer-div {
    width: 500px;
    height: 100px;
    background-color: #fff;
    transition: background-color 1s ease;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 2%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 340px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
  }

  .storyH3 {
    font-size: 150px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
    background: rgb(88, 101, 242);
    background: linear-gradient(180deg, rgb(36, 51, 219) 12%, rgb(67, 3, 185) 100%);
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    cursor: pointer;
    transition: transform .2s;
    margin-top: -15%;
  }

  .storyH3:hover {
    background: rgb(38, 47, 222);
    background: linear-gradient(180deg, rgba(38, 47, 222, 1) 12%, rgba(176, 68, 242, 1) 100%);
  }

  /* Animation */
  @keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  .bg {
    background-color: #17237e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 45%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutPS {
    width: 45%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutP3 {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    margin-bottom: 50px;
  }

  .aboutP3 p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 45px;
  }

  .aboutP p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 45px;
  }

  .aboutPDiv {
    background: rgb(251, 46, 249);
    background: linear-gradient(90deg, rgba(251, 46, 249, 1) 0%, rgba(9, 9, 121, 1) 99%);
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    width: 1100px;
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 20px;
    font-size: 35px;
    line-height: 75px;
  }

  .aboutPDiv2 {
    background-color: #2a32438a;
    backdrop-filter: blur(5px);
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    margin-bottom: 22px;
    margin-top: 22px;
    padding: 20px;
    font-size: 65px;
    line-height: 90px;
    color: white;
  }

  .hl {
    color: #1ee03e;
  }


  .bigLetters {
    font-size: 100px;
  }

  .ironfist {
    margin-top: auto;
    margin-left: -70px;
    z-index: 100000;
  }

  .ironfist img {
    width: 120px;
    height: 120px;

  }

  .storyConAndImg {
    display: flex;
    justify-content: center;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 45%;
    height: 45%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }


  .facetoface img {
    width: 30%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -27%;
  }

  .footerImg {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 27px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 25px;
    padding-top: 25px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 40px;
    margin-right: 40px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 55px;
    height: 55px;
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 35px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
       #009218;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 3.5rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 70px;
  }

  .logoUp {
    width: 95px;
    height: 95px;
    margin-top: -10px;
  }
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 90px;
    height: 90px;
    z-index: 1;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 10%;
    margin-left: 92%;
    margin-top: -8%;
    cursor: pointer;
    transition: transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    animation-name: move-clouds;
    animation-duration: 600s;
    animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    animation-name: move-clouds;
    animation-duration: 200s;
    animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    animation-name: move-clouds;
    animation-duration: 480s;
    animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @keyframes move-clouds {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .line {
    width: 100%;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;

    border: #ffa528 13px groove;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 120px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 45px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 50px;
    margin-left: 30px;
    margin-right: 30px;
    padding: 5px;
    width: 130px;
    height: 130px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 4px solid rgb(255, 255, 255);
    z-index: 1000000000000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 60px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 1000000000000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background: rgb(0, 120, 235);
    background: linear-gradient(180deg, rgba(0, 120, 235, 1) 0%, rgba(89, 154, 215, 1) 50%, rgba(0, 120, 235, 1) 100%);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 60px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: 8px rgb(0, 0, 0);
    border-style: double;
    z-index: 1000000000000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 150px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(240, 161, 97);
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .rm2Box {
    display: flex;
    justify-content: center;
    margin-right: auto;

  }

  .rm3Box {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .rmbox1 {
    clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    font-size: 150px;
    background: rgb(0, 89, 131);
    background: linear-gradient(95deg, rgba(0, 90, 131, 0.521) 0%, rgba(0, 85, 131, 0.527) 100%);
    backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 700px;
    padding: 30px;
    text-align: center;

  }

  .rmbox2 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    font-size: 65px;
    background-color: #ffffffb0;
    color: rgb(20, 44, 97);
    width: 800px;
    padding: 25px;
    text-align: center;
    backdrop-filter: blur(5px);
    text-align: center;
    align-items: center;
  }

  .rmbox1-3 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    text-align: center;

    font-size: 150px;
    background: rgb(0, 89, 131);
    background: linear-gradient(95deg, rgba(0, 90, 131, 0.521) 0%, rgba(0, 85, 131, 0.527) 100%);
    backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 700px;
    padding: 25px;
  }

  .rmbox2-3 {
    font-size: 65px;
    clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    text-align: center;
    background-color: #ffffffb0;
    color: rgb(20, 44, 97);
    width: 800px;
    padding: 25px;
    backdrop-filter: blur(5px);
  }

  .rmConMain {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    margin-top: 80px;
  }

  .scroll-effect-container {
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;

  }

  .scroll-effect {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  }

  .scroll-effect-relic {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-mint {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-tokenomics {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-footer {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-rm {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-footer {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {
    /*background: rgb(87, 102, 244);
    background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    background: rgb(42, 50, 67);
    background: linear-gradient(180deg, rgba(42, 50, 67, 0.623) 12%, rgba(26, 121, 58, 0.603) 100%);
    backdrop-filter: blur(5px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 1s ease-in-out;
    animation: fill-effect 1s ease-in-out;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate.animate {
    background-size: 100% 100%;

  }

  /*.animateC{
    background: rgb(244, 87, 179);
    background: linear-gradient(270deg, rgb(238, 77, 190) 0%, rgb(139, 8, 89) 51%);  background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 0.5s ease-in-out;
    animation: fill-effect 0.5s ease-in-out;
    font-style: italic;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }*/

  .animateC.animateC {
    background-size: 100% 100%;

  }

  /* Define the walkingNft class */
  .walkingNft {
    width: 30%;
    position: absolute;
    bottom: -0.8%;
    left: 0;
    animation: walk 20s linear infinite;

  }

  /* Define the walk animation */
  @keyframes walk {
    0% {
      transform: translateX(-5vw);
    }

    10% {
      transform: translateX(10vw) rotate(6deg);
    }

    20% {
      transform: translateX(20vw) rotate(0deg);
    }

    30% {
      transform: translateX(30vw) rotate(6deg);
    }

    40% {
      transform: translateX(40vw) rotate(0deg);
    }

    50% {
      transform: translateX(50vw) rotate(6deg);
    }

    60% {
      transform: translateX(60vw) rotate(0deg);
    }

    70% {
      transform: translateX(70vw) rotate(6deg);
    }

    80% {
      transform: translateX(80vw) rotate(0deg);
    }

    90% {
      transform: translateX(90vw) rotate(6deg);
    }

    100% {
      transform: translateX(100vw) rotate(0deg);
    }

  }


  .images2 {
    display: flex;
    justify-content: space-between;
  }

  .images2 img {
    width: 43%;
    margin-top: -30%;
    z-index: 10000;
  }



  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 12rem;

    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    padding-left: 100%;
    z-index: 20000000000000000;
    margin-top: -3.5px;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 12rem;
    line-height: 12rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
    z-index: 1000000000000;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 3rem;
    font-size: 75px;
    font-weight: 800;
    color: white;
    z-index: 1000000000000;

  }

  .line {
    background-color: rgb(157, 53, 206);
    height: 17vh;
    width: 120vw;
    border-bottom: 12px solid rgb(7, 153, 250);
    border-top: 12px solid rgb(7, 153, 250);
    rotate: 10deg;
    margin-left: -100px;
    display: flex;
    justify-content: space-between;
    margin-top: 15%;
  }

  .line2 {
    background-color: rgb(157, 53, 206);
    height: 15vh;
    width: 120vw;
    border-bottom: 12px solid rgb(7, 153, 250);
    border-top: 12px solid rgb(7, 153, 250);
    rotate: 5deg;
    margin-left: -40px;
    display: flex;
    margin-top: 1%;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .line2 img {
    width: 90px;
    height: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line img {
    width: 75px;
    height: 75px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .weapon {
    position: sticky;
    bottom: 0;
    width: 25%;
    margin-top: -15%;
  }

  .potionBottle {
    width: 23%;
    margin-top: -15%;
    z-index: 100000;
    margin-left: 20px;
  }

  .bawl {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 20px;
  }

  .ball {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 20px;
  }


  @keyframes float {
    0% {
      transform: translate(0, 0);
    }

    50% {
      transform: translate(0, 20px);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  .mashroom {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 70%;
    rotate: 25deg;
  }

  .lucky {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
  }

  .lucky img {
    width: 20%;
  }

  @keyframes shake {
    0% {
      transform: translate(0, 0) rotate(0);
    }

    10% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      transform: translate(0, 0) rotate(0);
    }
  }

  @keyframes zoom {
    0% {
      transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      transform: scale(1);
      /* End the animation with normal size image */
    }
  }


  .gifImg {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);

  }

  .gifImg2 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg3 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg4 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifMain {
    background-color: rgba(0, 0, 0, 0);
    margin-top: -5%;
  }



  .bubble-container {
    flex-flow: column;
    display: flex;
  }
}

@media screen and (min-width: 3840px) {
  .connect2-2-Mob{
    display: none;
  }
  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    bottom: 10%;
    z-index: 1000;
  }


  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    background-color: rgba(0, 0, 0, 0);
    contain: content;
  }

  .rmbg2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 2%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 100000000;

  }

  input {
    background-color: rgba(0, 30, 70, 0.568);
    border: white 1px solid;
    font-size: 20px;
    padding: 15px;
    color: #00d6fc;
    z-index: 100000000;
  }

  input:focus {
    background-color: #b3e9ff00;
    color: #00d6fc;
  }

  #wlBtn {
    background-color: rgba(0, 30, 70, 0.568);
    border: 1px solid rgb(16, 192, 223);
    color: #ffffff;
    width: 200px;
    z-index: 100000000;
  }

  #wlBtn:hover {
    font-size: 18px;
  }

  .wlLine {
    background-color: #000000;
    padding: 50px;
  }


  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background: rgb(70, 63, 101);
    background: radial-gradient(circle, rgba(70, 63, 101, 1) 0%, rgba(18, 19, 38, 1) 46%);
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /*contain: content;*/
  }

  .cont {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .cont2 {
    

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    z-index: 100000000000000;
  }


  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 {
    z-index: 100000000;

  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(0.9);
    }

    60% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  .logo2Intro {
    z-index: 200000000000000000000000000000000;
    width: 40%;
    padding-top: 27.9vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: heartbeat 2s ease-in-out infinite;
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    padding-top: 12vh;
    transition: transform 0.8s;
    z-index: 100000000;
  }

  .in2 img:hover {
    transform: scale(1.3);
  }


  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
  }

  .boxWrap2StoryR {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }

  .boxWrap2StoryT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }


  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    contain: content;
    padding-bottom: 2%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: 2%;
    z-index: 100000000000;
  }

  .boxWrap2SubR {
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap2Sub1 {
    contain: content;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .boxWrap2SubT2 {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .boxWrap2SubT {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #22e2d9;
  }

  .storyH {
    font-size: 200px;
    color: black;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 10px;
    font-style: normal;
    font-family: 'Exo', sans-serif;
  }

  .outer-div {
    width: 500px;
    height: 100px;
    background-color: #fff;
    transition: background-color 1s ease;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 2%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 520px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
  }

  .storyH3 {
    font-size: 185px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
    background: rgb(88, 101, 242);
    background: linear-gradient(180deg, rgb(36, 51, 219) 12%, rgb(67, 3, 185) 100%);
    width: 1300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    cursor: pointer;
    transition: transform .2s;
    margin-top: -15%;
  }

  .storyH3:hover {
    background: rgb(38, 47, 222);
    background: linear-gradient(180deg, rgba(38, 47, 222, 1) 12%, rgba(176, 68, 242, 1) 100%);
  }

  /* Animation */
  @keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  .bg {
    background-color: #17237e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 45%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutPS {
    width: 50%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutP3 {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    margin-bottom: 50px;
  }

  .aboutP3 p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 70px;
  }

  .aboutP p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 70px;
  }

  .aboutPDiv {
    background: rgb(251, 46, 249);
    background: linear-gradient(90deg, rgba(251, 46, 249, 1) 0%, rgba(9, 9, 121, 1) 99%);
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    width: 1600px;
    margin-bottom: 18px;
    margin-top: 18px;
    padding: 20px;
    font-size: 50px;
    line-height: 90px;
  }

  .aboutPDiv2 {
    background-color: #2a32438a;
    backdrop-filter: blur(5px);
    clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    margin-bottom: 50px;
    margin-top: 50px;
    padding: 20px;
    font-size: 100px;
    line-height: 120px;
    color: white;
  }

  .hl {
    color: #1ee03e;
  }


  .bigLetters {
    font-size: 120px;
  }

  .ironfist {
    margin-top: auto;
    margin-left: -70px;
    z-index: 100000;
  }

  .ironfist img {
    width: 140px;
    height: 140px;

  }

  .storyConAndImg {
    display: flex;
    justify-content: center;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 40%;
    height: 40%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }


  .facetoface img {
    width: 30%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -27%;
  }

  .footerImg {
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 15px;
    margin-right: 15px;
    transition: transform .2s;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 40px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 55px;
    padding-top: 55px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 55px;
    margin-right: 55px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 80px;
    height: 80px;
    margin-left: 30px;
    margin-right: 30px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 52px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
       #009218;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 6rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 95px;

  }

  .logoUp {
    width: 130px;
    height: 130px;
    margin-top: -20px;
  }
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 90px;
    height: 90px;
    z-index: 1;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 10%;
    margin-left: 92%;
    margin-top: -8%;
    cursor: pointer;
    transition: transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    animation-name: move-clouds;
    animation-duration: 600s;
    animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    animation-name: move-clouds;
    animation-duration: 200s;
    animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    animation-name: move-clouds;
    animation-duration: 480s;
    animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @keyframes move-clouds {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .line {
    width: 100%;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;

    border: #ffa528 13px groove;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 170px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 50px;
    margin-left: 30px;
    margin-right: 30px;
    padding: 5px;
    width: 170px;
    height: 170px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 4px solid rgb(255, 255, 255);
    z-index: 1000000000000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 60px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 1000000000000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background: rgb(0, 120, 235);
    background: linear-gradient(180deg, rgba(0, 120, 235, 1) 0%, rgba(89, 154, 215, 1) 50%, rgba(0, 120, 235, 1) 100%);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 80px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: 12px rgb(0, 0, 0);
    border-style: double;
    z-index: 1000000000000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 210px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(240, 161, 97);
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .rm2Box {
    display: flex;
    justify-content: center;
    margin-right: auto;

  }

  .rm3Box {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .rmbox1 {
    clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    font-size: 190px;
    background: rgb(0, 89, 131);
    background: linear-gradient(95deg, rgba(0, 90, 131, 0.521) 0%, rgba(0, 85, 131, 0.527) 100%);
    backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 1000px;
    padding: 30px;
    text-align: center;

  }

  .rmbox2 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    font-size: 80px;
    background-color: #ffffffb0;
    color: rgb(20, 44, 97);
    width: 800px;
    padding: 25px;
    text-align: center;
    backdrop-filter: blur(5px);
    text-align: center;
    align-items: center;
  }

  .rmbox1-3 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    text-align: center;

    font-size: 190px;
    background: rgb(0, 89, 131);
    background: linear-gradient(95deg, rgba(0, 90, 131, 0.521) 0%, rgba(0, 85, 131, 0.527) 100%);
    backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 1000px;
    padding: 25px;
  }

  .rmbox2-3 {
    font-size: 80px;
    clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    text-align: center;
    background-color: #ffffffb0;
    color: rgb(20, 44, 97);
    width: 800px;
    padding: 25px;
    backdrop-filter: blur(5px);
  }

  .rmConMain {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    margin-top: 80px;
  }

  .scroll-effect-container {
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;

  }

  .scroll-effect {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  }

  .scroll-effect-relic {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-mint {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-tokenomics {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-footer {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-rm {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-footer {
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {
    /*background: rgb(87, 102, 244);
    background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    background: rgb(42, 50, 67);
    background: linear-gradient(180deg, rgba(42, 50, 67, 0.623) 12%, rgba(26, 121, 58, 0.603) 100%);
    backdrop-filter: blur(5px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 1s ease-in-out;
    animation: fill-effect 1s ease-in-out;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate.animate {
    background-size: 100% 100%;

  }

  /*.animateC{
    background: rgb(244, 87, 179);
    background: linear-gradient(270deg, rgb(238, 77, 190) 0%, rgb(139, 8, 89) 51%);  background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 0.5s ease-in-out;
    animation: fill-effect 0.5s ease-in-out;
    font-style: italic;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }*/

  .animateC.animateC {
    background-size: 100% 100%;

  }

  /* Define the walkingNft class */
  .walkingNft {
    width: 30%;
    position: absolute;
    bottom: -0.8%;
    left: 0;
    animation: walk 20s linear infinite;

  }

  /* Define the walk animation */
  @keyframes walk {
    0% {
      transform: translateX(-5vw);
    }

    10% {
      transform: translateX(10vw) rotate(6deg);
    }

    20% {
      transform: translateX(20vw) rotate(0deg);
    }

    30% {
      transform: translateX(30vw) rotate(6deg);
    }

    40% {
      transform: translateX(40vw) rotate(0deg);
    }

    50% {
      transform: translateX(50vw) rotate(6deg);
    }

    60% {
      transform: translateX(60vw) rotate(0deg);
    }

    70% {
      transform: translateX(70vw) rotate(6deg);
    }

    80% {
      transform: translateX(80vw) rotate(0deg);
    }

    90% {
      transform: translateX(90vw) rotate(6deg);
    }

    100% {
      transform: translateX(100vw) rotate(0deg);
    }

  }


  .images2 {
    display: flex;
    justify-content: space-between;
  }

  .images2 img {
    width: 43%;
    margin-top: -30%;
    z-index: 10000;
  }



  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 15rem;

    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    padding-left: 100%;
    z-index: 20000000000000000;
    margin-top: -3.5px;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 15rem;
    line-height: 15rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
    z-index: 1000000000000;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 6rem;
    font-size: 100px;
    font-weight: 800;
    color: white;
    z-index: 1000000000000;

  }

  .line {
    background-color: rgb(157, 53, 206);
    height: 12vh;
    width: 120vw;
    border-bottom: 12px solid rgb(7, 153, 250);
    border-top: 12px solid rgb(7, 153, 250);
    rotate: 10deg;
    margin-left: -130px;
    display: flex;
    justify-content: space-between;
    margin-top: 15%;
  }

  .line2 {
    background-color: rgb(157, 53, 206);
    height: 12vh;
    width: 120vw;
    border-bottom: 12px solid rgb(7, 153, 250);
    border-top: 12px solid rgb(7, 153, 250);
    rotate: 3deg;
    margin-left: -40px;
    display: flex;
    margin-top: 1%;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .line2 img {
    width: 110px;
    height: 110px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line img {
    width: 110px;
    height: 110px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .weapon {
    position: sticky;
    bottom: 0;
    width: 25%;
    margin-top: -15%;
  }

  .potionBottle {
    width: 23%;
    margin-top: -15%;
    z-index: 100000;
    margin-left: 20px;
  }

  .bawl {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 20px;
  }

  .ball {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 20px;
  }


  @keyframes float {
    0% {
      transform: translate(0, 0);
    }

    50% {
      transform: translate(0, 20px);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  .mashroom {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 70%;
    rotate: 25deg;
  }

  .lucky {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
  }

  .lucky img {
    width: 20%;
  }

  @keyframes shake {
    0% {
      transform: translate(0, 0) rotate(0);
    }

    10% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      transform: translate(0, 0) rotate(0);
    }
  }

  @keyframes zoom {
    0% {
      transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      transform: scale(1);
      /* End the animation with normal size image */
    }
  }


  .gifImg {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);

  }

  .gifImg2 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg3 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg4 {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifMain {
    background-color: rgba(0, 0, 0, 0);
    margin-top: -5%;
  }



  .bubble-container {
    flex-flow: column;
    display: flex;
  }
}

.page-loader {
  width: 100%;
  height: 100vh;
  background: #272727;
  z-index: 200000000000000000000000000000000;
  background: rgb(70, 63, 101);
  background: radial-gradient(circle, rgb(32, 29, 53) 0%, rgb(10, 11, 22) 46%);
  position: fixed;
  overflow-x: hidden;
}